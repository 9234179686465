import {TablePageLayout} from "../../base/layout/TablePageLayout"
import {FDataGrid, IFDataGrid} from "../../ui/elements/FDataGrid"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import React, {useEffect, useState} from "react"
import {
    Check,
    HourglassEmpty,
    LocalShipping,
    PrecisionManufacturing,
    QuestionMark,
    Rule,
    TapAndPlay
} from "@mui/icons-material"
import {formatDate} from "../../../api/static_vars"
import {FabOrder, FabOrderLine, FabOrderLineMovement} from "../../../interfaces/FabOrder"
import {GridColDef, GridRowModesModel} from "@mui/x-data-grid"
import {useNavigate} from "react-router-dom"
import {Field} from "../../ui/elements/FabForm"
import {statusCodeToString} from "../../ui/utils/status_code_to_string"
import {Button} from "../../ui/inputs/Button"

export const TraceabilityPage = () => {
    const {
        productReferencesApi: {data: productReferences, getItemById: getProductReference},
        fabOrdersApi: {
            data: orders,
            loading,
            error,
            resetApiError,
            refreshItems: refreshOrders,
            forceRefresh: forceRefreshOrders,
            deleteItem: deleteFabOrder,
            getItemById: getOrderById
        },
        customersApi: {
            getItemById: getCustomer,
            data: customers,
        },
        usersApi: {
            getItemById: getUserById,
        },
        siteMovementsApi: {data: fabOrderLineMovements, getItemById: getFabOrderLineMovements, forceRefresh: forceMovements},

    } = useGlobalApi()
    const [dataLoading, setDataLoading] = React.useState<boolean>(loading)
    const [dataFiltered, setDataFiltered] = React.useState<FabOrder[]>(orders)
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [modalOpen, setModal] = useState<boolean>(false)
    const navigate = useNavigate()
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])
    useEffect(() => {
        forceRefreshOrders()
    }, [])
    useEffect(() => {
        setColumns(
            [
                {
                    field: 'type', headerName: 'Type', renderCell: (params: any) => (
                        params.internalOrderId.startsWith('OF') ? <PrecisionManufacturing/> : <LocalShipping/>
                    ), renderHeader: (params: any) => (
                        params.internalOrderId.startsWith('OF') ? "Fabrication" : "Distribution"
                    )
                },
                {field: 'internalOrderId', headerName: 'Identifiant', editable: true, width: 200},
                {
                    field: 'statusCode',
                    headerName: 'Statut',
                    editable: true,
                    width: 300,
                    renderCell: (params: any) => {
                        switch (params.statusCode) {
                            case "NSTA" :
                                return <span className={"font-bold"}><HourglassEmpty/>En attente</span>
                            case "STAR" :
                                return <span className={"font-bold"}><TapAndPlay/> En cours</span>
                            case "DONE" :
                                return <span className={"font-bold"}><Check/>Clôturé</span>
                            case "ECAR" :
                                return <span className={"font-bold"}><Rule/>Ecarts</span>
                            default :
                                return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                        }
                    },
                    renderHeader: (params: any) => {
                        return statusCodeToString(params.statusCode)
                    }
                },
                {
                    field: 'user',
                    headerName: 'Opérateur',
                    editable: false,
                    width: 150,
                    renderCell: (params: any) => {
                        for (const line of params.Lines) {
                            const firstWithUserId = line.Movements.find((movement: FabOrderLineMovement) => movement.userId !== 0 && movement.userId !== null)
                            if (firstWithUserId) {
                                return getUserById(firstWithUserId.userId)?.fullName ?? "Inconnu" // Or handle it as needed
                            }
                        }
                        return "N/A"
                    },
                    renderHeader: (params: any) => {
                        for (const line of params.Lines) {
                            const firstWithUserId = line.Movements.find((movement: FabOrderLineMovement) => movement.userId !== 0 && movement.userId !== null)
                            if (firstWithUserId) {
                                return getUserById(firstWithUserId.userId)?.fullName ?? "Inconnu" // Or handle it as needed
                            }
                        }
                        return ""
                    }
                },
                {
                    field: 'executionDate',
                    headerName: 'Date de production',
                    type: 'date',
                    width: 200
                },
                {
                    field: 'closingDate',
                    headerName: 'Date de clôture',
                    type: 'date',
                    width: 200
                },
                {
                    field: 'quantity_state',
                    headerName: 'Etat Quantité',
                    editable: true,
                    width: 150,
                    renderCell: (params: any) => {
                        if (params.Lines.length == 0) return "N/A"
                        if (params.Lines.length > 1) {
                            let linesWithoutHeader = [...params.Lines.slice(1)]
                            return `${linesWithoutHeader.reduce((prev, cur) => prev + cur.quantityDone, 0)}/${linesWithoutHeader.reduce((prev, cur) => prev + cur.quantity, 0)}`
                        } else return `${params.Lines[0].quantityDone}/${params.Lines[0].quantity}`
                    },
                },
                {
                    field: 'batch_header',
                    headerName: 'Lot(s)',
                    editable: false,
                    width: 150,
                    renderCell: (params: any) => {
                        const lines = params.Lines
                        let batchesString = ''
                        if (lines.length === 0) return "N/A"
                        if (params.internalOrderId.startsWith('OF')) {
                            batchesString = lines[0].batch ?? "N/A"
                        } else {
                            const batches = fabOrderLineMovements
                                .filter((movement: FabOrderLineMovement) => movement.fabOrderId === params.id)
                                .map((movement: FabOrderLineMovement) => movement.batch);
                            const batchesWithoutDups = batches.filter((b: string | undefined, index: number) => batches.indexOf(b) === index)
                            batchesString = batchesWithoutDups.length > 0 ? batchesWithoutDups.join(" | ") : "N/A"
                        }

                        return (
                            <span
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    position: 'relative',
                                    display: 'inline-block',
                                    maxWidth: '100%'
                                }}
                                title={batchesString}
                            >
                                {batchesString}
                            </span>
                        )
                    },
                    renderHeader: (params: any) => {
                        const lines = params.Lines
                        if (lines.length === 0) return ""
                        if (params.internalOrderId.startsWith('OF')) {
                            return lines[0].batch ?? "N/A"
                        } else {
                            const batches = fabOrderLineMovements
                                .filter((movement: FabOrderLineMovement) => movement.fabOrderId === params.id)
                                .map((movement: FabOrderLineMovement) => movement.batch);
                            const batchesWithoutDups = batches.filter((b: string | undefined, index: number) => batches.indexOf(b) === index)
                            return batchesWithoutDups.length > 0 ? batchesWithoutDups.join(" | ") : "N/A"
                        }

                    }
                },
                {
                    field: 'productReferenceId',
                    headerName: 'Référence produit',
                    editable: false,
                    width: 250,
                    renderCell: (params: any) => {
                        if (params.Lines.length == 0) return "N/A"
                        if (params.internalOrderId.startsWith('OD')) {
                            const products = params.Lines
                                .map((line: FabOrderLine) => getProductReference(line.productReferenceId)?.label ?? null)
                                .filter((label: string): label is string => !!label); // supprime undefined / null
                            const productsWithoutDups = products.filter((b: string | undefined, index: number) => products.indexOf(b) === index)
                            const productsString = productsWithoutDups.length > 0 ? productsWithoutDups.join(" | ") : "N/A"
                            return (
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                        display: 'inline-block',
                                        maxWidth: '100%'
                                    }}
                                    title={productsString}
                                >
                                {productsString}
                            </span>
                            )
                        }
                        return getProductReference(params.Lines[0].productReferenceId)?.label ?? "N/A"
                    },
                    renderHeader: (params: any) => {
                        if (params.Lines.length == 0) return "N/A"
                        if (params.internalOrderId.startsWith('OD')) {
                            const products = params.Lines
                                .map((line: FabOrderLine) => getProductReference(line.productReferenceId)?.label ?? null)
                                .filter((label: string): label is string => !!label); // supprime undefined / null
                            const productsWithoutDups = products.filter((b: string | undefined, index: number) => products.indexOf(b) === index)
                            return productsWithoutDups.length > 0 ? productsWithoutDups.join(" | ") : "N/A"
                        }
                        return getProductReference(params.Lines[0].productReferenceId)?.label ?? "N/A"
                    }
                },
                {
                    field: 'customer',
                    headerName: 'Client',
                    editable: false,
                    width: 250,
                    renderCell: (params: any) => {
                        if (params.Lines.length > 1) {
                            return getCustomer(params.Lines[0].customerId)?.label ?? "Inconnu"
                        } else return "N/A"
                    },
                    renderHeader: (params: any) => {
                        if (params.Lines.length > 1) {
                            return getCustomer(params.Lines[0].customerId)?.label ?? ""
                        } else return ""
                    }
                }
            ]
        )
    }, [productReferences])
    useEffect(() => {
        setDataFiltered(orders)
    }, [orders])
    const showMore = (id: number) => {
        let order = getOrderById(id)
        if (order) {
            if (order.internalOrderId.startsWith("OD")) {
                navigate(`/dashboard/shipping/${id}`)
            } else {
                navigate(`/dashboard/production/${id}`)
            }
        }
    }
    const dataGrid: IFDataGrid = {
        rowModesModel: rowModesModel,
        setRowModesModel: setRowModesModel,
        rows: dataFiltered,
        loading: dataLoading,
        columns: columns,
        showMore: showMore,
        tableHeight: 'h-[60%]'
    }
    const [filterInputs, setFilterInputs] = useState<Field[]>([
        {
            label: "Nom de l'ordre",
            type: 'text',
            required: false,
            name: 'orderInternalId',
            active: false,
            value: ""
        },
        {
            label: 'Lot',
            type: 'text',
            required: false,
            name: 'batch',
            active: false,
            value: ""
        },
        {
            label: 'Date de création après',
            type: 'date',
            required: false,
            name: 'createdAt',
            active: false,
            value: ""
        },
        {
            label: 'Date de cloture après',
            type: 'date',
            required: false,
            name: 'closingDate',
            active: false,
            value: ""
        },
        {
            label: "Date d'éxecution après",
            type: 'date',
            required: false,
            name: 'executionDate',
            active: false,
            value: ""
        },
        {
            label: 'Article',
            type: 'autocomplete',
            required: false,
            data: productReferences,
            name: 'productReferenceId',
            active: false,
            value: ""
        },
        {
            label: 'Client',
            type: 'autocomplete',
            required: false,
            data: customers,
            name: 'customerId',
            active: true,
            value: ""
        },
    ])
    useEffect(() => {

        let fromValues = [...orders]
        filterInputs.forEach(filter => {
            if (filter.value != undefined && filter.value != "" && filter.active) {
                switch (filter.name) {
                    case 'orderInternalId':
                        fromValues = fromValues.filter(order =>
                            order.internalOrderId && order.internalOrderId.toLowerCase().includes(filter.value!.toLowerCase())
                        )
                        break
                    case 'batch':
                        fromValues = fromValues.filter(order =>
                            order.Lines.some(line => {
                                if (order.internalOrderId.startsWith('OF') ) {
                                    return line.batch && line.batch.toLowerCase().includes(filter.value!.toLowerCase())
                                } else {
                                    return line.Movements.some(move => move.batch && move.batch.toLowerCase().includes(filter.value!.toLowerCase()))
                                }
                            }
                            )
                        )
                        break
                    case 'createdAt':
                        const toCompareCreate = new Date(filter.value!)
                        fromValues = fromValues.filter(order => {
                            const orderDate = new Date(order.createdAt!)
                            return orderDate > toCompareCreate
                        })
                        break
                    case 'closingDate':
                        const toCompareClosing = new Date(filter.value!)
                        fromValues = fromValues.filter(order => {
                            if (order.closingDate != undefined) {
                                const orderDate = new Date(order.closingDate)
                                return orderDate > toCompareClosing
                            } else return false
                        })
                        break
                    case 'executionDate':
                        const toCompareExecution = new Date(filter.value!)
                        fromValues = fromValues.filter(order => {
                            if (order.executionDate != undefined) {
                                const executionDate = new Date(order.executionDate)
                                return executionDate > toCompareExecution
                            } else return false
                        })
                        break
                    case 'productReferenceId':
                        fromValues = fromValues.filter(order => order.Lines.find(line =>  line.productReferenceId === Number(filter.value!)))
                        break
                    case 'customerId':
                        fromValues = fromValues.filter(order => order.Lines.find(line => line.customerId != null && line.customerId === Number(filter.value!)))
                        break
                }
            }
        })
        setDataFiltered(fromValues)
    }, [filterInputs, orders])
    const onChangeFilterInputOverload = (column: string, value: string) => {
        const newFilters = [...filterInputs]
        if (orders.length > 0) {
            const filterInputIndex = newFilters.findIndex(filter => filter.name === column)
            if (newFilters[filterInputIndex] != null) {
                if (value != "" || value != null) {
                    newFilters[filterInputIndex].active = true
                    newFilters[filterInputIndex].value = value
                } else {
                    newFilters[filterInputIndex].active = false
                    newFilters[filterInputIndex].value = undefined
                }
            }
            setFilterInputs(newFilters)
        }
    }
    return (<>
        <TablePageLayout
            title={"TRACABILITÉ"}
            forceRefreshData={forceRefreshOrders}
            errors={[]}
            resetError={resetApiError}
            loadingReference={loading}
            filterInputs={filterInputs}
            table={dataGrid}
            canExport
            onChangeFilterInputOverload={onChangeFilterInputOverload}
        />
    </>)
}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                