import React, {ChangeEvent, ChangeEventHandler, MouseEventHandler, useEffect, useState} from "react"
import {Button} from "../../ui/inputs/Button"
import {ArrowPathIcon, PlusCircleIcon} from "@heroicons/react/24/solid"
import {IFModal} from "../../ui/interfaces/elements/IFModal"
import {FModal} from "../../ui/elements/FModal"
import {FDataGrid, IFDataGrid} from '../../ui/elements/FDataGrid'
import {Field, FormGenerator} from "../../ui/elements/FabForm"
import Papa, {UnparseConfig} from 'papaparse'
import {GridColDef} from "@mui/x-data-grid"
import {DateToDateHours} from "../../ui/utils/date_to_hours"
import {DateToDate} from "../../ui/utils/date_to_date"
interface IFilterInput {
    label: string,
    column: string,
}

interface ITablePageLayoutProps {
    title: string,
    Editor?: React.FC<IFModal>,
    checkboxs?: React.ReactNode,
    forceRefreshData: () => void,
    errors: Error[] | null,
    resetError: () => void,
    loadingReference: boolean,
    children?: React.ReactNode,
    table?: IFDataGrid,
    filterInputs?: Field[]
    onChangeFilterInputOverload?: (name: string, newValue: string) => void
    canExport?: boolean
    isFeminineLabel?: boolean
}

export const TablePageLayout: React.FC<ITablePageLayoutProps> = ({
                                                                     title,
                                                                     Editor,
                                                                     checkboxs,
                                                                     table,
                                                                     filterInputs,
                                                                     forceRefreshData,
                                                                     children,
                                                                     errors,
                                                                     resetError,
                                                                     loadingReference,
                                                                     onChangeFilterInputOverload,
                                                                     canExport,
                                                                     isFeminineLabel
                                                                 }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [currentRows, setCurrentRows] = useState<any[]>([])
    const onCloseModal: MouseEventHandler<HTMLButtonElement> = (e) => {
        setModalOpen(false)
    }
    const openModal = () => {
        if (table && table.rows.length > 0) {
            setCurrentRows(table.rows)
        }
        setModalOpen(true)
    }
    const config: UnparseConfig = {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ";",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
        columns: table?.columns.map(col => col.field)  //or array of strings
    }
    const translateHeadersAndRows = (
        rows: any[],
        columns: GridColDef[]
    ): { translatedRows: any[]; translatedHeaders: string[] } => {
        // Récupère les noms traduits des en-têtes à partir de `headerName`
        const translatedHeaders: string[] = columns.map(col => col.headerName || col.field)
        // Re-map les lignes pour correspondre aux en-têtes traduits
        const translatedRows = rows.map(row => {
            const newRow: Record<string, any> = {}
            for (const col of columns) {
                const headerKey = col.headerName || col.field;
                if (col.type === 'date')
                {
                    newRow[headerKey] = row[col.field] ? DateToDateHours(row[col.field]) : ''
                } else if (col.type === 'dateTime') {
                    newRow[headerKey] = row[col.field] ? DateToDate(row[col.field]) : ''
                }else if (col.renderHeader) {
                    newRow[headerKey] = col.renderHeader(row)
                } else if (col.renderCell) {
                    const data = col.renderCell(row)
                    if (typeof data === "string") {
                        newRow[headerKey] = '\t'+data
                    } else {
                        newRow[headerKey] = row[col.field]
                    }
                } else {
                    newRow[headerKey] = row[col.field]
                }
            }
            return newRow;
        });

        return { translatedRows, translatedHeaders };
    };
    const exportData = () => {
        if (!table || currentRows.length === 0) return;
        // Traduire les entêtes et les données
        const { translatedRows, translatedHeaders } = translateHeadersAndRows(
            currentRows,
            table.columns
        );

        // Ajouter les en-têtes traduits dans la configuration
        config.columns = translatedHeaders;

        // Générer le CSV avec les données traduites
        const csv = Papa.unparse(translatedRows, config);
        const csvData = new Blob([String.fromCharCode(0xFEFF), csv], { type: "text/csv;charset=utf-8;" });
        const csvURL = window.URL.createObjectURL(csvData);

        // Créer un lien de téléchargement temporaire
        const testLink = document.createElement("a");
        testLink.href = csvURL;
        testLink.setAttribute(
            "download",
            `${title.toLowerCase()}_${new Date().toISOString()}.csv`
        );
        document.body.appendChild(testLink); // Ajoutez le lien au DOM pour Firefox
        testLink.click();
        document.body.removeChild(testLink); // Supprimez le lien après le clic
    };
    useEffect(() => {
        if (table && table.columns.length > 0) {
            if (!table.columns.find(s => s.field === 'createdAt')) {
                table.columns = [...table.columns,
                    {
                        field: 'createdAt',
                        headerName: 'Date de création',
                        type: 'date',
                        width: 200
                    },
                    {
                        field: 'updatedAt',
                        headerName: 'Date de mise à jour',
                        type: 'date',
                        width: 200
                    }
                ]
                config.columns = table.columns.map(col => col.field)  //or array of strings
            } else {
                table.columns = [...table.columns]
            }
            }
    }, [table])
    return (<>
        <div className="w-full">
            <div className="panel_page_entete">
                <span className="text-2xl m-auto">{title}</span>
            </div>
            <div className="flex w-full justify-around m-auto mb-5">
                {Editor && <Button onClick={openModal} className={"w-1/3"} text={`Ajouter ${isFeminineLabel ? 'une' : 'un'} ${title.toLowerCase()}`}
                                   leftIcon={<PlusCircleIcon/>}/>}
                <Button onClick={() => forceRefreshData()} loading={loadingReference} className={"w-1/6"}
                        text={"Rafraîchir"}
                        leftIcon={<ArrowPathIcon/>}/>
                {canExport && <Button onClick={exportData} className={"w-1/3"} text={`Exporter les données`}
                                   leftIcon={<PlusCircleIcon/>}/>}
            </div>
            {checkboxs &&
                <div className="w-1/2 flex justify-around items-center m-auto mt-2 mb-2 bg-white">
                    {checkboxs}
                </div>
            }
            {filterInputs && filterInputs.length > 0 &&
                <div className="w-full flex justify-around items-center m-auto mt-2 pb-4 bg-white">
                    <FormGenerator flex size={"!w-[48%]"} fields={filterInputs}
                                   onChangeOverload={onChangeFilterInputOverload} apiError={[]}/>
                </div>
            }

        </div>
        {table && <FDataGrid rows={table.rows} setRows={setCurrentRows} loading={table.loading} columns={table.columns}
                             showMore={table.showMore} disableEdit={table.disableEdit}
                             deleteRow={table.deleteRow} updateRow={table.updateRow}
                             rowModesModel={table.rowModesModel} setRowModesModel={table.setRowModesModel} tableHeight={table.tableHeight}/>}
        {children && children}
        {errors && errors.length > 0 && !modalOpen &&
            <FModal
                className="bg-white w-3/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl !mt-5"
                canClose onClose={resetError} title={"Attention"}>
                {
                    errors.map(error => {
                        return <p
                            className="p-2 m-2 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error.message}</p>
                    })
                }
            </FModal>
        }
        {
            Editor && modalOpen && <Editor onClose={onCloseModal} setModalOpen={setModalOpen}/>
        }

    </>)
}