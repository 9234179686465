import {Button} from "../../ui/inputs/Button";
import Logo from "../../../assets/logo_fabtracer.png";
import {useFabApp} from "../../../hooks/useFabApp";
import React, {useEffect, useState} from "react";
import {getToken, verifiyToken} from "../../../api/authApi";
import {IApiReturnDefault} from "../../../interfaces/IApiFuncs";
import useLocalStorage from "../../../hooks/useLocalStorage";
import background from "../../../assets/backLogin.png";
import {redirect, useNavigate} from "react-router-dom";
import {TextInput} from "../../ui/inputs/TextInput";
import {LoginOutlined} from "@mui/icons-material";

export const Login = () => {
    // @ts-ignorelighter
    // noinspection JSUnresolvedReference
    const basename = window.config.REACT_APP_BASENAME ?? '/';
    const [loginValue, setLoginValue] = useState<string>("");
    const [onError, setOnError] = useState<string | null>(null);
    const [infoMessage, setInfoMessage] = useState<string>("Veuillez saisir vos identifiants");
    const [passwordValue, setPasswordValue] = useState<string>("");
    const {gotToken, isAuthenticated, setIsAuth, setUser} = useFabApp();
    const [tokenLocalItemValue, setTokenLocalItem] = useLocalStorage("fabToken", "");
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (gotToken) {
            setInfoMessage("Récupération de la session en cours...");
            setLoading(true);
            verifiyToken(tokenLocalItemValue).then(resp => {
                if (resp.success) {
                    setInfoMessage("Token validé, redirection ...");
                    setIsAuth(true);
                    setOnError(null);
                    setUser(resp.user ?? null);
                    if (window.location.href.indexOf('dashboard') === -1) {
                        navigate('/dashboard');
                    } else {
                        redirect(window.location.href);
                    }
                } else {
                    setLoading(false);
                    setOnError("Session expirée");
                }
            });
        } else {
            setLoading(false);
            setInfoMessage("Veuillez saisir vos identifiants");
        }
    }, [gotToken]);
    const handleLoginSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        getToken(loginValue, passwordValue).then((res: IApiReturnDefault) => {
            if (!res.success) {
                setOnError(res.message ?? "Erreur");
            } else {
                setTokenLocalItem(res.data.value);
                setUser(res.data.user ?? null)
                setInfoMessage(res.message ?? "ok");
                setIsAuth(true);
                setOnError(null);
                if (window.location.href.indexOf('dashboard') === -1) {
                    navigate('/dashboard');
                } else {
                    redirect(window.location.href);
                }
            }
            setLoading(false);
        });
    };
    // style={{backgroundImage: `url(${basename ? basename + "/" : ""}${background})`}}>
    return (
        <>
            {
                !isAuthenticated ?
                    <div className="fab_login_container h-full w-full flex">
                        <div className="w-1/4 h-full bg-cover rounded-l-md"
                             style={{backgroundImage: `url(${basename ? basename + "/" : ""}${background})`}}>
                        </div>
                        <form onSubmit={handleLoginSubmit}
                              className="h-full w-3/4">
                            <div
                                className=" w-full m-auto bg-fab_congress-blue-800 flex flex-col justify-between items-center">
                                <div className="bg-fab_congress-blue-800 w-full h-20 flex">
                                    <img src={Logo} alt="Logo de fabtracer" className={"h-2/4 m-auto"}/>
                                </div>
                                <p className="text-white">POUR</p>
                                <img alt={"Logo du client"} src={`${window.location.protocol}//${window.location.host}/splash.png`} className="m-auto"/>
                            </div>

                            <div
                                className="mt-10 w-2/3 m-auto flex gap-3 bg-fab_congress-blue-100 opacity-95 rounded-md h-3/6">

                                <div
                                    className="w-3/4 m-auto flex flex-col opacity-95 p-5 gap-5 rounded-md">
                                    {/*<p className="text-2xl m-auto">CONNEXION AU PANEL</p>*/}
                                    {onError ?
                                        <div
                                            className="text-white bg-fab_light_red-400 rounded-md m-auto p-2 h-10 w-full flex justify-center">
                                            <p className="text-ellipsis overflow-hidden text-center">{onError}</p>
                                        </div> :
                                        <div
                                            className="text-white bg-fab_ucla_blue-600 rounded-md m-auto p-2 h-10 w-full flex justify-center">
                                            <p className="text-ellipsis overflow-hidden text-center">{infoMessage}</p>
                                        </div>
                                    }
                                    <TextInput value={loginValue} setterChange={setLoginValue} label={"Login : "}
                                               className={"flex"}/>
                                    <TextInput value={passwordValue} setterChange={setPasswordValue}
                                               label={"Mot de passe : "}
                                               className={"flex"} password/>
                                    {/*<LoginInput value={loginValue} setterChange={setLoginValue}/>*/}
                                    <Button text="Connexion" leftIcon={<LoginOutlined/>} fullStyle loading={loading}/>
                                </div>
                            </div>
                        </form>
                    </div> :
                    <div className="h-screen w-full">
                        <div className="h-full w-full flex">
                            <div className="w-10/12 h-2/3 bg-fab_congress-blue-200 rounded-lg m-auto">
                                <img src='../../../public/splash.png")' alt="" className="m-auto h-1/3"/>
                                <div className="w-full h-1/4 flex justify-center gap-2 m-auto">
                                    <div className="fab_button w-1/6 h-full rounded-lg flex">
                                        <img src={Logo} alt="" className="m-auto"/>
                                    </div>
                                    <div className="fab_button w-1/6 h-1/h-full rounded-lg flex">
                                        <img alt="" className="m-auto splash"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};