import React, {createContext, useContext, ReactNode, useState} from 'react'
import {useApi} from "../api/_useApi"
import {Role} from "../interfaces/Role"
import {User} from "../interfaces/User"
import {FabOrder, FabOrderLine, FabOrderLineMovement} from "../interfaces/FabOrder"
import {ISite} from "../interfaces/ISite"
import {ICompany} from "../interfaces/ICompany"
import {DashboardInfos} from "../interfaces/DashboardInfos"
import {Tag} from "../interfaces/Tag"
import {ApplicationDevice} from "../interfaces/ApplicationDevice"
import {ContainerType} from "../interfaces/Container"
import {ProductCategory, ProductReference} from "../interfaces/Product"
import {ICustomer} from "../interfaces/ICustomer"
import {IStatusCode} from "../interfaces/processes/IStatusCode"
import {ExternalApplication, ExternalSubject} from "../interfaces/ExternalApplication"
import {BASE_GET_OPTIONS, BASE_POST_OPTIONS, BASE_PUT_OPTIONS, BASE_URL} from "../api/static_vars"
import useLocalStorage from "../hooks/useLocalStorage"
import {ImportRuleOrder} from "../interfaces/ImportRuleOrder"
import {BusinessAccount} from "../interfaces/BusinessAccount"
import {ImportBatch} from "../interfaces/ImportBatch"
import { FabFile } from '../interfaces/Image'

// Interface pour tous les API hooks
type GlobalApiContextType = {
    companiesApi: ReturnType<typeof useApi<ICompany>>;
    sitesApi: ReturnType<typeof useApi<ISite>>;
    customersApi: ReturnType<typeof useApi<ICustomer>>;
    businessAccountsApi: ReturnType<typeof useApi<BusinessAccount>>;
    tagsApi: ReturnType<typeof useApi<Tag>>;
    fabOrdersApi: ReturnType<typeof useApi<FabOrder>>;
    fabOrderLinesApi: ReturnType<typeof useApi<FabOrderLine>>;
    fabOrderLineMovementsApi: ReturnType<typeof useApi<FabOrderLineMovement>>;
    siteMovementsApi: ReturnType<typeof useApi<FabOrderLineMovement>>;
    rolesApi: ReturnType<typeof useApi<Role>>;
    usersApi: ReturnType<typeof useApi<User>>;
    applicationDevicesApi: ReturnType<typeof useApi<ApplicationDevice>>;
    externalApplicationsApi: ReturnType<typeof useExternalApplicationOverrideApi>;
    externalSubjectsApi: ReturnType<typeof useApi<ExternalSubject>>;
    importRuleOrdersApi: ReturnType<typeof useImportRuleOrderApi>;
    importBatchesApi: ReturnType<typeof useApi<ImportBatch>>;
    containerTypesApi: ReturnType<typeof useApi<ContainerType>>;
    productCategoriesApi: ReturnType<typeof useApi<ProductCategory>>;
    productReferencesApi: ReturnType<typeof useApi<ProductReference>>;
    statusCodesApi: ReturnType<typeof useApi<IStatusCode>>;
    taggedContainersApi: ReturnType<typeof useApi<Tag>>;
    productImageApi: ReturnType<typeof useApi<FabFile>>;
    dashboardApi: ReturnType<typeof useApi<DashboardInfos>>;
    sabcProductionFabOrderApi: ReturnType<typeof useApi<FabOrder>>;
    sabcShippingFabOrderApi: ReturnType<typeof useApi<FabOrder>>;
    sabcAlertProductionOrdersApi: ReturnType<typeof useApi<FabOrder>>;
    sabcAlertShippingOrdersApi: ReturnType<typeof useApi<FabOrder>>;
}

// Création du contexte
const GlobalApiContext = createContext<GlobalApiContextType | undefined>(undefined)

// Le GlobalApiProvider qui englobe toutes les APIs
export const GlobalApiProvider = ({children}: { children: ReactNode }) => {
    const companiesApi = useApi<ICompany>({endpoint: 'web/companie'})
    const sitesApi = useApi<ISite>({endpoint: 'web/site'})
    const customersApi = useApi<ICustomer>({endpoint: 'web/customer'})
    const businessAccountsApi = useApi<BusinessAccount>({endpoint: 'web/business_account'})
    const tagsApi = useApi<Tag>({endpoint: 'web/tag', siteDependant: true})
    const fabOrdersApi = useApi<FabOrder>({
        endpoint: 'web/fab_order',
        siteDependant: true,
        fabOrderTypeDependant: true
    })
    const fabOrderLinesApi = useApi<FabOrderLine>({
        endpoint: 'web/fab_order_line',
        specificIdentifierDependant: true,
    })
    const fabOrderLineMovementsApi = useApi<FabOrderLineMovement>({
        endpoint: 'web/fab_order_line_movement',
        specificIdentifierDependant: true,
    })
    const siteMovementsApi = useApi<FabOrderLineMovement>({
        endpoint: 'web/site/movement',
        siteDependant: true
    })
    const rolesApi = useApi<Role>({endpoint: 'web/role'})
    const usersApi = useApi<User>({endpoint: 'web/user'})
    const applicationDevicesApi = useApi<ApplicationDevice>({endpoint: 'web/application_device'})
    const externalApplicationsApi = useExternalApplicationOverrideApi()
    const externalSubjectsApi = useApi<ExternalSubject>({endpoint: 'web/external_subject'})
    const importRuleOrdersApi = useImportRuleOrderApi()
    const importBatchesApi = useApi<ImportBatch>({endpoint: 'web/import_batche'})
    const containerTypesApi = useApi<ContainerType>({endpoint: 'web/container_type'})
    const productCategoriesApi = useApi<ProductCategory>({endpoint: 'web/product_categorie'})
    const productReferencesApi = useApi<ProductReference>({endpoint: 'web/product_reference'})
    const statusCodesApi = useApi<IStatusCode>({endpoint: 'web/status_code'})
    const taggedContainersApi = useApi<Tag>({
        endpoint: 'web/tagged_container',
        siteDependant: true,
    })
    //SABC
    const sabcProductionFabOrderApi = useApi<FabOrder>({
        endpoint: 'sabc/production_order',
        siteDependant: true,
    })
    const sabcShippingFabOrderApi = useApi<FabOrder>({
        endpoint: 'sabc/shipping_order',
        siteDependant: true,
    })
    const sabcAlertProductionOrdersApi = useApi<FabOrder>({
        endpoint: 'sabc/alert_production_order',
        siteDependant: true,
    })
    const sabcAlertShippingOrdersApi = useApi<FabOrder>({
        endpoint: 'sabc/alert_shipping_order',
        siteDependant: true,
    })


    const dashboardApi = useApi<DashboardInfos>({
        endpoint: 'web/portafeu/dashboard',
        siteDependant: true
    })
    const productImageApi = useApi<FabFile>({
        endpoint: 'web/product_image_upload',
        isFileManagerEndpoint: true,
    })
    return (
        <GlobalApiContext.Provider value={{
            companiesApi,
            sitesApi,
            customersApi,
            businessAccountsApi,
            tagsApi,
            fabOrdersApi,
            fabOrderLinesApi,
            fabOrderLineMovementsApi,
            siteMovementsApi,
            rolesApi,
            usersApi,
            applicationDevicesApi,
            externalApplicationsApi,
            externalSubjectsApi,
            importRuleOrdersApi,
            importBatchesApi,
            containerTypesApi,
            productCategoriesApi,
            productReferencesApi,
            statusCodesApi,
            taggedContainersApi,
            dashboardApi,
            productImageApi,
            sabcProductionFabOrderApi,
            sabcShippingFabOrderApi,
            sabcAlertShippingOrdersApi,
            sabcAlertProductionOrdersApi
        }}>
            {children}
        </GlobalApiContext.Provider>
    )
}

// Hook pour accéder facilement aux APIs
export const useGlobalApi = (): GlobalApiContextType => {
    const context = useContext(GlobalApiContext)
    if (!context) {
        throw new Error('useGlobalApi must be used within a GlobalApiProvider')
    }
    return context
}

function useExternalApplicationOverrideApi() {
    const api = useApi<ExternalApplication>({endpoint: 'web/external_application'})
    const [tokenLocalItemValue] = useLocalStorage("fabToken", "")
    const generateToken = async (id: string) => {
        try {
            api.setLoading(true)
            const opts = {...BASE_POST_OPTIONS}
            opts.headers = new Headers()
            opts.headers.append("Authorization", `Bearer ${tokenLocalItemValue}`)
            opts.body = new FormData()
            opts.body.append('appId', id)
            const res = await fetch(`${BASE_URL}web/external_application_access`, opts)
            api.setLoading(false)
            return res
        } catch (e: any) {
            api.setCustomError([new Error(e.message)])
            return Promise.resolve(undefined)
        }
    }

    return {
        ...api,
        generateToken,
    }
}

function useImportRuleOrderApi() {
    const api = useApi<ImportRuleOrder>({endpoint: 'web/import_rule_order'})
    const [tokenLocalItemValue] = useLocalStorage("fabToken", "")
    const generateJsonExample = async (id: string) => {
        try {
            api.setLoading(true)
            const opts = {...BASE_GET_OPTIONS}
            opts.headers = new Headers()
            opts.headers.append("Authorization", `Bearer ${tokenLocalItemValue}`)
            opts.headers.append("ruleId", id)
            const res = await fetch(`${BASE_URL}web/import_rule_order_json_example`, opts)
            api.setLoading(false)
            return res
        } catch (e: any) {
            api.setCustomError([new Error(e.message)])
            return Promise.resolve(undefined)
        }
    }
    return {
        ...api,
        generateJsonExample
    }
}