import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {IButton} from "../../ui/interfaces/inputs/IButton"
interface ISabcDashButton extends IButton {
    title: string,
    textTop: string,
    textBottom: string
}
export const SabcDashButton:
    FC<ISabcDashButton> =
    ({text, leftIcon, onClick, route, className, title, textTop, textBottom}) => {
        const navigate = useNavigate();
        const [active, setActive] = useState<boolean>();
        const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (onClick) {
                onClick(e);
            }
            if (route) {
                navigate(route);
            }
        }
        useEffect(() => {
            if (route) {
                const routeWithoutSlashes = route.replace(/\//g, ''); // Retirer tous les '/'
                const urlWithoutSlashes = document.URL.replace(/\//g, ''); // Retirer tous les '/'
                const regex = new RegExp(`${routeWithoutSlashes}($|[^a-zA-Z0-9_])`);
                const match = urlWithoutSlashes.match(regex);
                if (match != null) {
                    setActive(true)
                    return
                }
            }
            setActive(false)
        }, [navigate]);
        return (
            <button
                onClick={handleClick}
                className={`fab_dashboard_element ${className} !p-1`}>
                <div className="w-full h-full flex flex-col justify-between text-xl">
                    <div className="w-full font-bold">{title}</div>
                    <div className="w-full h-full flex">
                        <div className="h-full w-1/2 flex flex-col">
                            {leftIcon}
                            {text}
                        </div>
                        <div className="w-1/2 h-full flex flex-col justify-center text-left">
                            <div className="w-full">{textTop}</div>
                            <div className="w-full">{textBottom}</div>
                        </div>
                    </div>
                </div>
            </button>
        )
    }