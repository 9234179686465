import React, {FC, useEffect, useState} from 'react';
import {FabAppContext} from '../hooks/useFabApp';
import useLocalStorage from '../hooks/useLocalStorage';
import {ISite} from "../interfaces/ISite";
import {ModalSiteChooser} from '../components/base/pages/editors/ModalSiteChooser'
import {ICompany} from "../interfaces/ICompany";
import {User} from "../interfaces/User";
import {IEncodingProcess} from "../interfaces/processes/IEncodingProcess";
import {IStatusAssignmentProcess} from "../interfaces/processes/IStatusAssignmentProcess";
import {IVerificationProcess} from "../interfaces/processes/IVerificationProcess";
import {GlobalApiProvider} from "./GlobalApiProvider"
import {useCompaniesApi} from "../api/base_apis"
interface FabAppProviderProps {
  children?: React.ReactNode;
}

const FabAppProvider: FC<FabAppProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [tokenLocalItemValue] = useLocalStorage("fabToken", "");
  const [chooseSiteIndexModalOpen, setChooseSiteIndexModalOpen] = useState<boolean>(false);
  const addNotification = (notification: Notification) => {
    setNotifications((prevNotifications) => [...prevNotifications, notification]);
  };
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [gotToken, setGotToken] = useState<boolean>(false)
  const [isAuthenticated, setIsAuth] = useState<boolean>(false)
  const [user, setUser] = useState<User | null>(null)
  const [error, setError] = useState<string>('')
  const [companyChosen, setCompanyChosen] = useState<ICompany | null>(null)
  const [siteChosen, setSiteChosen] = useState<ISite | null>(null)
  const [specificIdentifierChosen, setSpecificIdentifierChosen] = useState<number | null>(null)
  const {data: companies} = useCompaniesApi()
  useEffect(() => {
    if (tokenLocalItemValue != '') setGotToken(true)
    else setGotToken(false)
  }, [tokenLocalItemValue]);
  useEffect(() => {
    if (companyChosen) return
    let site: ISite | undefined = undefined
    let company: ICompany | undefined
    if (!companies) return
    if (user && user.siteId) {
      let company = companies.find(c => c.id == user?.companyId)
      if (company) {
        let site = company.Sites.find(s => s.id == user?.siteId)
        if (site) {
          setSiteChosen(site)
          setCompanyChosen(company)
        }
      }
    }
  }, [user])

  useEffect(() => {
    if (!isAuthenticated) return;
    if (!siteChosen) setChooseSiteIndexModalOpen(true);
    else setChooseSiteIndexModalOpen(false);
  }, [siteChosen, isAuthenticated]);
  // useEffect(() => {
  //   if(!isAuthenticated) return;
  //   if (siteChosen == null) setChooseSiteIndexModalOpen(true)
  //   else setChooseSiteIndexModalOpen(false)
  // }, [siteChosen, isAuthenticated]);
  const handleRedirectCallback = (...p: any) => {};

  return (
    <>
      <FabAppContext.Provider value={{
        isLoading,
        gotToken,
        isAuthenticated,
        user,
        error,
        siteChosen,
        specificIdentifierChosen,
        companyChosen,
        handleRedirectCallback,
        notifications,
        addNotification,
        setIsAuth,
        setUser,
        setSiteChosen,
        setSpecificIdentifierChosen,
        setCompanyChosen}}>
        <GlobalApiProvider>
        {children}
        { chooseSiteIndexModalOpen && <ModalSiteChooser /> }
        </GlobalApiProvider>
      </FabAppContext.Provider>
    </>
  )
};
export default FabAppProvider;