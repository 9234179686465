import {NavBar} from "../layout/NavBar";
import {Outlet} from "react-router-dom";
import FabEntete from "../../../assets/Entete.png";
import React, {useEffect} from "react"
import {ReactComponent as PalletSvg} from "../../../assets/pallet_512dp_000000_FILL0_wght400_GRAD0_opsz48.svg"
import {ReactComponent as Package2} from "../../../assets/package_2_512dp_000000_FILL0_wght400_GRAD0_opsz48.svg"
import {ReactComponent as LocalShippingSvg} from "../../../assets/local_shipping_512dp_000000_FILL0_wght400_GRAD0_opsz48.svg"
import {useDashboardApi} from "../../../api/base_apis";
import {
    ArrowPathIcon,
    ArrowsRightLeftIcon,
    CalendarIcon,
    CalendarDateRangeIcon
} from "@heroicons/react/24/solid"
import {FDashButton} from "../../ui/inputs/FDashButton";
import {
    Check, CrisisAlert,
    HourglassEmpty,
    Inventory,
    LocalShipping, LocalShippingOutlined, PrecisionManufacturing, PrecisionManufacturingOutlined, PaletteOutlined,
    Rule, TapAndPlay,
} from "@mui/icons-material"
import {Button} from "../../ui/inputs/Button"
import {SabcDashButton} from "../../sabc/components/SabcDashButton"


export const DashBoardPage = () => {
    const {data: dashboards, loading, forceRefresh} = useDashboardApi();
    useEffect(() => {
        forceRefresh()
    },[])
    return (
        <div className="w-full h-full bg-fab_app_container flex flex-col">
            <div className="panel_page_entete">
                <span className="text-2xl m-auto">TABLEAU DE BORD DE SYNTHÈSE</span>
            </div>
            <Button onClick={() => forceRefresh()} loading={loading} className={"w-1/3"}
                    text={"Rafraîchir"}
                    leftIcon={<ArrowPathIcon/>}/>
            {
                dashboards.length > 0 && <>
                    <div className={"w-full p-5 h-48 flex gap-5 justify-around"}>
                        <SabcDashButton
                            leftIcon={<CalendarDateRangeIcon
                                className={"h-24"}/>
                            }
                            route={'movements'}
                            className={"w-1/2"}
                            title={"Cette semaine"}
                            text={dashboards[0].countMovementsInWeek + " Mouvements cette semaine"}
                            textTop = {(dashboards[0].countProdOrder.week ?? 0) + " Ordre(s) de fabrication"}
                            textBottom = {(dashboards[0].countShippingOrder.week ?? 0) + " Ordre(s) de distribution"}
                        />
                        <SabcDashButton
                            leftIcon={<CalendarIcon
                                className={"h-24"}/>
                            }
                            route={'movements'}
                            title={"Depuis toujours"}
                            className={"w-1/2"}
                            text={dashboards[0].countAllMovements + " Mouvements au total"}
                            textTop = {(dashboards[0].countProdOrder.all ?? 0) + " Ordre(s) de fabrication"}
                            textBottom = {(dashboards[0].countShippingOrder.all ?? 0) + " Ordre(s) de distribution"}
                        />
                    </div>
                    <div className="w-11/12 h-2/3 flex gap-2 m-auto flex-wrap">
                        <div
                            className="w-[49%] m-auto h-1/4 flex bg-gray-200 shadow-xl border-2 rounded border-gray-500 text-center hover:border-fab_congress-blue-500 text-lg">
                            <div className="w-full h-full flex items-center">
                                <div className="w-1/2 h-full text-center m-auto flex flex-col items-center text-8xl gap-2">
                                    <PrecisionManufacturingOutlined fontSize={'inherit'} />
                                    <span className={"text-2xl"}>{dashboards[0].countProdOrder.all} Ordre(s) de fabrication</span>
                                </div>
                                <div className="w-1/2 mr-5 h-full flex flex-col justify-center text-right">
                                    <span className={"flex justify-around "}><HourglassEmpty className={"w-1/3 mt-auto"}/> <span
                                        className={"text-xl w-2/3 text-left"}>{dashboards[0].countProdOrder.waiting} Ordre(s) en attente</span></span>
                                    <span className={"flex justify-around"}><TapAndPlay className={"w-1/3 mt-auto"}/> <span
                                        className={"w-2/3 text-xl text-left"}>{dashboards[0].countProdOrder.started} Ordre(s) en cours</span></span>
                                    <span className={"flex justify-around"}><Check className={"w-1/3 mt-auto"}/> <span
                                        className={"w-2/3 text-xl text-left"}>{dashboards[0].countProdOrder.done} Ordre(s) clôturé(s)</span></span>
                                    <span className={"flex justify-around"}><CrisisAlert className={"w-1/3 text-red-700 mt-auto"}/> <span
                                        className={"w-2/3 text-xl text-left text-red-700"}>{dashboards[0].countProdOrder.alerts} Ordre(s) en alerte</span></span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-[49%] m-auto h-1/4 flex bg-gray-200 shadow-xl border-2 rounded border-gray-500 text-center hover:border-fab_congress-blue-500 text-lg">
                            <div className="w-full h-full flex items-center ">
                                <div
                                    className="w-1/2 h-full text-center m-auto flex flex-col items-center text-8xl gap-2">
                                    <LocalShippingOutlined fontSize={'inherit'}/>
                                    <span className={"text-2xl"}>{dashboards[0].countShippingOrder.all} Ordre(s) de distribution</span>
                                </div>
                                <div className="w-1/2 mr-5 h-full flex flex-col justify-center text-right">
                                    <span className={"flex justify-around "}><HourglassEmpty
                                        className={"w-1/3 mt-auto"}/> <span
                                        className={"text-xl w-2/3 text-left"}>{dashboards[0].countShippingOrder.waiting} Ordre(s) en attente</span></span>
                                    <span className={"flex justify-around"}><TapAndPlay
                                        className={"w-1/3 mt-auto"}/> <span
                                        className={"w-2/3 text-xl text-left"}>{dashboards[0].countShippingOrder.started} Ordre(s) en cours</span></span>
                                    <span className={"flex justify-around"}><Check className={"w-1/3 mt-auto"}/> <span
                                        className={"w-2/3 text-xl text-left"}>{dashboards[0].countShippingOrder.done} Ordre(s) clôturé(s)</span></span>
                                    <span className={"flex justify-around"}><Rule className={"w-1/3 mt-auto"}/> <span
                                        className={"w-2/3 text-xl text-left"}>{dashboards[0].countShippingOrder.gap} Ordre(s) en écart</span></span>
                                    <span className={"flex justify-around"}><CrisisAlert
                                        className={"w-1/3 text-red-700 mt-auto"}/> <span
                                        className={"w-2/3 text-xl text-left text-red-700"}>{dashboards[0].countShippingOrder.alerts} Ordre(s) en alerte</span></span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-[49%] m-auto h-1/4 flex bg-gray-200 shadow-xl border-2 rounded border-gray-500 text-center hover:border-fab_congress-blue-500 text-lg">
                            <div className="w-full h-full flex items-center">
                                <div
                                    className="w-1/2 h-full text-center m-auto flex flex-col items-center text-9xl gap-2">
                                    <PalletSvg className={"m-auto h-2/3 w-full"}/>
                                    <span className={"text-2xl"}>{dashboards[0].countPalletContainers.all} Palette(s) enregistrée(s)</span>
                                </div>
                                <div className="w-1/2 mr-5 h-full flex flex-col justify-center text-right">
                                    <span className={"flex justify-around "}><Inventory className={"w-1/3"}/> <span
                                        className={"text-xl w-2/3 text-left"}>{dashboards[0].countPalletContainers.prod} Palette(s) produite(s)</span></span>
                                    <span className={"flex justify-around"}><LocalShipping className={"w-1/3"}/> <span
                                        className={"w-2/3 text-xl text-left"}>{dashboards[0].countPalletContainers.sends} Palette(s) expediée(s)</span></span>
                                    <span className={"flex justify-around"}><HourglassEmpty className={"w-1/3"}/> <span
                                        className={"w-2/3 text-xl text-left"}>{dashboards[0].countPalletContainers.available} Palette(s) disponible(s)</span></span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-[49%] m-auto h-1/4 flex bg-gray-200 shadow-xl border-2 rounded border-gray-500 text-center hover:border-fab_congress-blue-500 text-lg">
                        <div className="w-full h-full flex items-center ">
                                <div className="w-1/2 h-full text-center m-auto flex flex-col">
                                    <Package2 className={"m-auto h-2/3 w-full"}/>
                                    <span className={"text-2xl"}>{dashboards[0].countCaseContainers.all} Casier(s) enregistré(s)</span>
                                </div>
                                <div className="w-1/2 mr-5 h-full flex flex-col justify-center text-right">
                                    <span className={"flex justify-around "}><Inventory className={"w-1/3"}/> <span
                                        className={"text-xl w-2/3 text-left"}>{dashboards[0].countCaseContainers.prod} Casier(s) produit(s)</span></span>
                                    <span className={"flex justify-around"}><LocalShipping className={"w-1/3"}/> <span
                                        className={"w-2/3 text-xl text-left"}>{dashboards[0].countCaseContainers.sends} Casier(s) expedié(s)</span></span>
                                    <span className={"flex justify-around"}><HourglassEmpty className={"w-1/3"}/> <span
                                        className={"w-2/3 text-xl text-left"}>{dashboards[0].countCaseContainers.available} Casier(s) disponible(s)</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};
export const Dashboard = () => {
    return (
        <>
            <div className="w-full h-full flex">
                <NavBar/>
                <div className="w-10/12 h-full flex flex-col">
                    <div className="w-full flex h-16 bg-fab_congress-blue-800">
                        {/*<img alt="Logo RECO" src={LogoReco}/>*/}
                        <img alt="Logo FabTracer" src={FabEntete} className="w-full h-full"/>
                    </div>
                    <div className="w-full h-full bg-fab_app_container flex flex-col">
                        <Outlet/>
                    </div>
                </div>

                {/*<NotificationsBar/>*/}
            </div>
        </>
    );
};