import '../fab_ui.css'
import React, {FC, useEffect, useState} from 'react';
import {IButton, INavButton} from "../interfaces/inputs/IButton"
import {useNavigate} from "react-router-dom";
import {useFabApp} from "../../../hooks/useFabApp";
export const FNavButton:
    FC<INavButton> =
    ({forceOpen, text, leftIcon, onClick, route}) => {
        const navigate = useNavigate();
        const [active, setActive] = useState<boolean>();
        const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (onClick) {
                onClick(e);
            }
            if (route) {
                navigate(route);
            }
        }
        useEffect(() => {
            if (route) {
                // Nettoyer la route et l'URL
                const sanitizedRoute = route.replace(/\/?\d+/g, '').split('/').filter(Boolean);
                const sanitizedUrl = document.URL.replace(/\/?\d+/g, '').split('/').filter(Boolean);

                // Récupérer la portion après le hashtag dans l'URL
                const hashTagPosition = sanitizedUrl.findIndex(part => part.includes('#'));
                const urlAfterHashtag = hashTagPosition !== -1
                    ? sanitizedUrl.slice(hashTagPosition + 1)
                    : sanitizedUrl;

                // Vérifier si on est sur "dashboard" ou une autre route
                const currentMainSegment = urlAfterHashtag[0] === 'dashboard' && urlAfterHashtag[1]
                    ? urlAfterHashtag[1]
                    : urlAfterHashtag[0];

                if (currentMainSegment === sanitizedRoute[0]) {
                    setActive(true);
                    forceOpen();
                    return;
                }
            }
            setActive(false)
        }, [navigate]);
        return (
            <button
                onClick={handleClick}
                className={`w-full flex duration-150 m-2 cursor-pointer ${active ? "bg-fab_congress-blue-500 text-white shadow-md" : "hover:bg-fab_congress-blue-500 hover:shadow-md"} `}>
                <div className="rounded-sm flex p-1">
                    {leftIcon}
                    <div className="h-max-full pr-1 pl-1">{text}</div>
                </div>
            </button>
        )
    }