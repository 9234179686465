import {GridColDef} from "@mui/x-data-grid"
import { DateToDate } from "./date_to_date";
import { DateToDateHours } from "./date_to_hours";

export const translateHeadersAndRows = (
    rows: any[],
    columns: GridColDef[]
): { translatedRows: any[]; translatedHeaders: string[] } => {
    // Récupère les noms traduits des en-têtes à partir de `headerName`
    const translatedHeaders: string[] = columns.map(col => col.headerName || col.field)
    // Re-map les lignes pour correspondre aux en-têtes traduits
    const translatedRows = rows.map(row => {
        const newRow: Record<string, any> = {}
        for (const col of columns) {
            const headerKey = col.headerName || col.field;
            if (col.type === 'date')
            {
                newRow[headerKey] = row[col.field] ? DateToDateHours(row[col.field]) : ''
            } else if (col.type === 'dateTime') {
                newRow[headerKey] = row[col.field] ? DateToDate(row[col.field]) : ''
            }else if (col.renderHeader) {
                newRow[headerKey] = col.renderHeader(row)
            } else if (col.renderCell) {
                const data = col.renderCell(row)
                if (typeof data === "string") {
                    newRow[headerKey] = '\t'+data
                } else {
                    newRow[headerKey] = row[col.field]
                }
            } else {
                newRow[headerKey] = row[col.field]
            }
        }
        return newRow;
    });

    return { translatedRows, translatedHeaders };
};