import '../fab_ui.css'
import React, {FC, useEffect, useState} from 'react'
import {IExtendedFileInput} from "../interfaces/inputs/IExtendedInput"

export const FileInput:
    // COMPOSANT NON FONCTIONNEL
    FC<IExtendedFileInput> = ({dataName, label, setterChange, className, typeRequired}) => {
    const [file, setFile] = useState<string | null>(null)
    const [stringFilesAccepted, setStringFilesAccepted] = useState<string>("")

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        if (e.target.files != undefined && e.target.files.length > 0) {
            let selectedFile = e.target.files[0]
            if (selectedFile && selectedFile.type.includes('image')) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    setFile(reader.result as string)
                }
                reader.readAsDataURL(selectedFile)
                return
            }
        }
        setFile(null)
    }

    useEffect(() => {
        if (file) setterChange ? setterChange(dataName, file) : null
    }, [file])
    useEffect(() => {
        if (typeRequired === "IMAGE") {
            setStringFilesAccepted(".png,.jpg")
        }
    }, [typeRequired])
    return (
        <div className={`${className} h-[64px]`}>
            {label && <div className="w-1/3 text-left m-auto border-b pb-2">{label}</div>}
            <label className="fab_button button_full w-1/3 flex justify-center items-center ">
                <span className="">Choisir un fichier</span>
                <input
                    accept={stringFilesAccepted}
                    onChange={handleChange}
                    className="hidden h-0"
                    type="file"
                />
            </label>
            <div className="w-1/3">
                {file && <div className="border"><img src={file} alt={`Image importée`}
                                                      style={{width: "64px", height: "64px"}}/></div>}
            </div>
        </div>


    )
}