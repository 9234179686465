import {useState, useEffect} from 'react'
import useLocalStorage from "../hooks/useLocalStorage"
import {BASE_GET_OPTIONS, BASE_URL, BASE_PUT_OPTIONS, BASE_DELETE_OPTION, BASE_POST_OPTIONS} from "./static_vars"
import {Identifiable} from "../interfaces/Identifiable"
import {useFabApp} from "../hooks/useFabApp"

interface UseApiOptions<T extends Identifiable> {
    endpoint: string
    siteDependant?: boolean
    fabOrderTypeDependant?: boolean
    specificIdentifierDependant?: boolean
    onDelete?: (id: number) => void
    isFileManagerEndpoint?: boolean
}

export function useApi<T extends Identifiable>({
                                                   endpoint,
                                                   siteDependant,
                                                   specificIdentifierDependant,
                                                   onDelete,
                                                    isFileManagerEndpoint
                                               }: UseApiOptions<T>): {
    resetApiError: () => void
    addItem: (element: T) => Promise<void | T | boolean | number>
    data: T[]
    deleteItem: (id: number) => Promise<void>
    getItemById: (id: number) => (T | undefined)
    updateItem: <U extends Identifiable>(element: U) => Promise<U | undefined>
    loading: boolean
    setLoading: (loading: boolean) => void
    error: Error[] | null
    setCustomError: (e: Error[]) => void
    refreshItems: () => Promise<void>
    forceRefresh: () => void
} {
    const [data, setData] = useState<T[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<Error[] | null>(null)
    const [tokenLocalItemValue] = useLocalStorage("fabToken", "")
    const {siteChosen, specificIdentifierChosen, setIsAuth} = useFabApp()
    const [lastUpdate, setLastUpdate] = useState<number | null>()
    const getItemById = (id: number): T | undefined => {
        return data.find(item => item.id == id)
    }
    const resetApiError = () => {
        setError([])
    }
    const setCustomError = (e: Error[]) => {
        setError(e)
    }
    const addItem = async (element: T): Promise<void | T | boolean | number> => {
        resetApiError()
        try {
            setLoading(true)
            const opts = {...BASE_POST_OPTIONS}
            opts.headers = new Headers()
            opts.headers.append("Authorization", `Bearer ${tokenLocalItemValue}`)
            opts.headers.append("Site", siteChosen?.id.toString() ?? "0")
            opts.body = new FormData()
            if (isFileManagerEndpoint) opts.body.append('file', element as any)
            else opts.body.append('newItem', JSON.stringify(element))
            const response = await fetch(`${BASE_URL}${endpoint}`, opts)
            if (!response.ok) {
                if (response.status === 401) {
                    setIsAuth(false)
                } else {
                    let errorText = await response.text()
                    throw new Error(errorText ??  'Erreur lors de l\'ajout de l\'élément' + response.status)
                }
            } else {
                await forceRefresh()
                const contentType = response.headers.get("content-type")
                if (contentType) {
                    if (contentType.includes("application/json")) {
                        // Vérifier si le content-type est JSON et si la réponse n'est pas vide
                        // Ici on attend un retour de tableau objet
                        try {
                            let addedItem: T[] = await response.json()
                            if (addedItem && addedItem[0]) {
                                return Promise.resolve(addedItem[0])
                            }
                        } catch (error) {
                            console.error("Erreur lors du parsing JSON:", error)
                            return Promise.reject("Erreur de parsing JSON")
                        }
                    } else if (contentType.includes("text/plain")) {
                        // Ici on traite les retours tel que les IDS
                        const value = await response.json()
                        return Promise.resolve(value)
                    }
                } else {
                    // Ici on dit juste que ca c'est bien passé
                    return Promise.resolve(true)
                }
            }
        } catch (e: any) {
            setLoading(false)
            setError([new Error(e.message)])
            if (e.code === 401) setIsAuth(false)
            else
            return Promise.resolve(false)
        }
    }
    const deleteItem = async (id: number) => {
        resetApiError()
        try {
            setLoading(true)
            const opts = {...BASE_DELETE_OPTION}
            opts.headers = new Headers()
            opts.headers.append("Authorization", `Bearer ${tokenLocalItemValue}`)
            opts.body = new FormData()
            opts.body.append('itemId', id.toString())
            const response = await fetch(`${BASE_URL}${endpoint}`, opts)
            if (!response.ok) {
                if (response.status === 401) {
                    setIsAuth(false)
                } else {
                    let errorText = await response.text()
                    throw new Error(errorText ?? 'Erreur lors de la suppression de l\'élément')
                }
            } else {
                if (onDelete) {
                    onDelete(id)
                }
                fetchAllItems(true).then()
            }
        } catch (e: any) {
            setLoading(false)
            setError([new Error(e.message)])
        }
    }
    const updateItem = async <U extends Identifiable>(element: U): Promise<U | undefined> => {
        resetApiError()
        try {
            const updatedItem = element as unknown as T
            const opts = {...BASE_PUT_OPTIONS}
            opts.headers = {Authorization: `Bearer ${tokenLocalItemValue}`}
            opts.body = new FormData()
            if (isFileManagerEndpoint) opts.body.append('file', updatedItem as any)
            opts.body.append('item', JSON.stringify(updatedItem))
            const response = await fetch(`${BASE_URL}${endpoint}`, opts)
            if (!response.ok) {
                if (response.status === 401) {
                    setIsAuth(false)
                } else {
                    let errorText = await response.text()
                    throw new Error(errorText ?? 'Erreur lors de la suppression de l\'élément')
                }
            } else {
                let editedItem: U = await response.json()
                return Promise.resolve(editedItem)
            }
        } catch(e: any) {
            setError([new Error(e.message)])
            return Promise.resolve(undefined)
        }
    }
    const fetchAllItems = async (force: boolean = false) => {
        resetApiError()
        if (!tokenLocalItemValue) return
        const now = new Date().getTime()
        // if (!force) {
        //     const diff = now - (lastUpdate ?? 0)
        //     if (lastUpdate && diff < 30000) {
        //         setData((prevState) => [...prevState])
        //         return
        //     }
        // }
        try {
            setLoading(true)
            const opts = {...BASE_GET_OPTIONS}
            opts.headers = new Headers()
            opts.headers.append('Authorization', `Bearer ${tokenLocalItemValue}`)
            if (siteDependant) {
                if ((!siteChosen || siteChosen!.id == 0)) return
                else opts.headers.append("Site", siteChosen?.id.toString() ?? "0")
            }
            if (specificIdentifierDependant && !specificIdentifierChosen) return
            else opts.headers.append("SpecificId", specificIdentifierChosen?.toString() ?? "0")
            const response = await fetch(`${BASE_URL}${endpoint}s`, opts)
            if (!response.ok || response.body === null) {
                if (response.status === 401) {
                    setIsAuth(false)
                } else {
                    throw new Error(response.statusText ?? 'Erreur lors de la récupération des éléments')
                }
            }
            const newData = await response.json()
            setLastUpdate(now)
            setData(newData)
            setLoading(false)
        } catch (e: any) {
            setError([new Error(e.message)])
            return Promise.resolve(undefined)
        }
    }
    const refreshItems = async () => {
        setLoading(true)
        fetchAllItems().then(() => setLoading(false))
    }
    const forceRefresh = async () => {
        setLoading(true)
        fetchAllItems(true).then(() => setLoading(false))
    }
    useEffect(() => {
        if (siteDependant) {
            fetchAllItems(true).then(() => setLoading(false))
        }
    }, [siteChosen])
    useEffect(() => {
        if (specificIdentifierDependant) {
            fetchAllItems(true).then(() => setLoading(false))
        }
    }, [specificIdentifierChosen])
    useEffect(() => {
        if (tokenLocalItemValue) {
            fetchAllItems(true).then(() => setLoading(false))
        }
    }, [tokenLocalItemValue])

    return {
        data,
        loading,
        setLoading,
        error,
        setCustomError,
        resetApiError,
        getItemById,
        addItem,
        updateItem,
        deleteItem,
        refreshItems,
        forceRefresh
    }
}