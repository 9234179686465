import React, {FC, useEffect, useLayoutEffect, useMemo, useState} from 'react'
import {DateToDateHours} from "../utils/date_to_hours"
import {
    GridActionsCellItem,
    GridColDef,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridValidRowModel
} from "@mui/x-data-grid"
import {Identifiable} from "../../../interfaces/Identifiable"
import {Check, DragHandle, KeyboardArrowDown, KeyboardArrowUp, RestartAlt} from "@mui/icons-material"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import {Button} from "../../ui/inputs/Button"
import {TextInput} from "../inputs/TextInput"
import {GridSingleSelectColDef} from "@mui/x-data-grid/models/colDef/gridColDef"
import {FAutoComplete} from "../inputs/FAutoComplete"
import {OnSelectionChangeParams} from '../interfaces/inputs/IFAutoComplete'
import {FModal} from "./FModal"
import {DateToDate} from "../utils/date_to_date"
import {FCheckBox} from "../inputs/FCheckBox"

export interface IFDataGrid {
    rows: any[],
    setRows?:  React.Dispatch<React.SetStateAction<any[]>>,
    loading: boolean,
    ignoreDates?: boolean,
    disableEdit?: boolean,
    columns: GridColDef[],
    deleteRow?: (id: number) => Promise<void>,
    afterDelete?: () => void,
    afterUpdate?: () => void,
    updateRow?: <U extends Identifiable>(element: U) => Promise<U | undefined>,
    rowModesModel: GridRowModesModel,
    setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>,
    confirmDeleteText?: string,
    showMore?: (id: number) => void,
    tableHeight?: string,
}

interface DeletedRow {
    label?: string;
    login?: string;
    internalOrderId?: string;
    identifier?: string;
    type?: string;
    deviceIdentifier?: string;
    id: number;
}

export const FDataGrid: FC<IFDataGrid> = ({
                                              rowModesModel,
                                              setRowModesModel,
                                              rows,
                                              setRows,
                                              loading,
                                              ignoreDates,
                                              disableEdit,
                                              columns,
                                              updateRow,
                                              deleteRow,
                                              afterDelete,
                                              afterUpdate,
                                              confirmDeleteText,
                                              showMore,
                                              tableHeight
                                          }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchQuery, setSearchQuery] = useState('')
    const [totalRows, setTotalRows] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [paginatedRows, setPaginatedRows] = useState<any[]>([])
    const [data, setData] = useState<any[]>([])
    const [originalData, setOriginalData] = useState<any[]>([])
    const [selectedId, setSelectedId] = useState<number>(0)
    const [sortConfig, setSortConfig] = useState<{
        column: string;
        direction: 'asc' | 'desc'
    } | null>({column: 'updatedAt', direction: 'desc'})
    const [columnsFormatted, setColumnsFormatted] = useState<GridValidRowModel[] | GridSingleSelectColDef []>([])
    const [visible, setVisible] = useState<boolean>(false)
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false)
    const [deletedRow, setDeletedRow] = useState<DeletedRow | undefined>(undefined)
    const [idInEditMode, setIdInEditMode] = useState<any>(null)
    useEffect(() => {
        if (selectedId === 0) getIdFromUrl()
    }, [selectedId])

    useEffect(() => {
        setData(rows)
        setOriginalData(rows)
    }, [rows])

    useEffect(() => {
        let columnsForTable: GridColDef<GridValidRowModel>[] = []
        if (!columns.find(s => s.field === 'createdAt')) {
            columnsForTable = [...columns,
                {
                    field: 'createdAt',
                    headerName: 'Date de création',
                    type: 'date',
                    width: 200
                },
                {
                    field: 'updatedAt',
                    headerName: 'Date de mise à jour',
                    type: 'date',
                    width: 200
                }
            ]
        } else {
            columnsForTable = [...columns]
        }

        if (updateRow || deleteRow) columnsForTable.push({
            field: 'actions', headerName: 'Actions', type: 'actions', width: 100, cellClassName: 'actions',
            renderCell: ({id}: any) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
                if (idInEditMode && idInEditMode.id === id) {
                    return <>
                        <Button
                            soloIcon={<SaveIcon/>}
                            fullStyle
                            onClick={handleSave}
                        />,
                        <Button
                            soloIcon={<CancelIcon/>}
                            fullStyle
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setIdInEditMode(null)
                            }}
                        /></>
                }
                const cellActions: JSX.Element[] = []
                if (updateRow) {
                    cellActions.push(
                        <Button
                            soloIcon={<EditIcon/>}
                            fullStyle
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                handleEditClick(id)
                            }}
                        />
                    )
                }
                if (deleteRow) {
                    cellActions.push(
                        <Button
                            soloIcon={<DeleteIcon/>}
                            fullStyle
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                handleDeleteClick(id)
                            }}
                        />
                    )
                }


                return cellActions.map(elt => {
                    return elt
                })
            }
        })
        setColumnsFormatted(columnsForTable)
    }, [columns, idInEditMode])
    const handleSetCurrentPage = (page: number) => {
        setVisible(false)
        setTimeout( () => setCurrentPage(page), 100)
    }
// Dans votre composant FDataGrid
    const sortedData = useMemo(() => {
        if (!sortConfig) return data;
        const isNumericColumn = data.every((row) => !isNaN(Number(row[sortConfig.column])));
        const origData = [...data];
        const col = columnsFormatted.find( (c) => c.field === sortConfig.column);
        if (!col) return data
        const isDateColumn = col.type === 'date' || col.type === 'dateTime'
        origData.sort((a, b) => {
            if (a === null || a === 0 || a === "") return -1;
            if (b === null || b === 0 || b === "") return -1;
            const valA = col.renderHeader ? col.renderHeader(a) : a[sortConfig.column]
            const valB = col.renderHeader ? col.renderHeader(b) : b[sortConfig.column]
            //console.log(valB)
            if (isNumericColumn && !isDateColumn) {
                return sortConfig.direction === 'asc'
                    ? Number(valA) - Number(valB)
                    : Number(valB) - Number(valA);
            } else if (isDateColumn && !isNumericColumn) {
                return sortConfig.direction === 'asc'
                    ? new Date(valA).getTime() - new Date(valB).getTime()
                    : new Date(valB).getTime() - new Date(valA).getTime();
            } else {
                return sortConfig.direction === 'asc'
                    ? String(valA).localeCompare(String(valB), undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                    : String(valB).localeCompare(String(valA), undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    });
            }
        });
        if (setRows) setRows(origData);
        return origData;
    }, [sortConfig, data]);

// Utilisez sortedData au lieu de data pour afficher les lignes
    useEffect(() => {
        setColumnWidths([])
        if (sortedData.length === 0) {
            setTotalRows(0)
            setTotalPages(0)
            setPaginatedRows([])
            setCurrentPage(1)
        } else {
            const calcTotalRows = sortedData.length;
            const calcTotalPages = Math.ceil(calcTotalRows / rowsPerPage);
            setTotalRows(calcTotalRows);
            setTotalPages(calcTotalPages);
            const startIndex = (currentPage - 1) * rowsPerPage;
            const endIndex = startIndex + rowsPerPage;
            const calcPaginatedRows = sortedData.slice(startIndex, endIndex);
            setIdInEditMode(null);
            setPaginatedRows(calcPaginatedRows);
            if (currentPage > calcTotalPages) {
                setCurrentPage(1);
            }
        }
    }, [sortedData, rowsPerPage, currentPage]);

    const getIdFromUrl = () => {
        const pathname = window.location.pathname // Obtenir le chemin complet de l'URL
        const match = pathname.match(/\/([^/]+)$/) // Trouver tout après le dernier slash
        if (match) {
            setSelectedId(parseInt(match[1])) // Le premier groupe capturé (l'ID après le dernier slash)
        }
    }

    const showMoreClick = (event: React.MouseEvent<HTMLDivElement>, id: number) => {
        event.preventDefault()
        setSelectedId(id)
        if (showMore) {
            const url = `${document.URL}/${id}`
            showMore(id)
        }
    }
    const returnFirstNonEmptyIdentifier = (row: DeletedRow) => {
        return row.label ?? row.login ?? row.internalOrderId ?? row.identifier ?? row.type ?? row.deviceIdentifier ?? row.id
    }
    const handleEditClick = (id: number) => {
        const row = rows.find((r) => r.id === id)
        if (row) {
            setIdInEditMode({...row}) // Création d'une nouvelle référence
        }
    }
    const handleDeleteClick = (id: number) => {
        let row = rows.find(r => r.id == id)
        setDeletedRow(row)
        setConfirmDeleteModalOpen(true)
    }

    const confirmDelete = (e: React.MouseEvent) => {
        e.preventDefault()
        if (!deleteRow || deletedRow == undefined) return
        deleteRow(deletedRow.id).then(() => {
            setConfirmDeleteModalOpen(false)
            setConfirmDeleteModalOpen(false)
            setDeletedRow(undefined)
            if (afterDelete != null) {
                afterDelete()
            }
        })
    }

    //const csvConfig = mkConfig({ useKeysAsHeaders: false, title: "Preparations", fieldSeparator: ';', columnHeaders: rows });

    const resetSorting = () => {
        setData([...originalData]) // Restaurer les données initiales
        setIdInEditMode(null)
        setSortConfig({column: 'updatedAt', direction: 'desc'}) // Réinitialiser l'état du tri
    }

    const handleSort = (e: any, column: string) => {
        e.preventDefault()
        // Déterminer le nouvel ordre

        // Mettre à jour l'état
        setSortConfig((prevState) => {
            let direction: 'asc' | 'desc' = 'desc'
            if (prevState && prevState.column === column) {
                direction = prevState.direction === 'asc' ? 'desc' : 'asc'
            }
            return  {column, direction}
        })
    }
    const [columnWidths, setColumnWidths] = useState<number[]>([])

    useEffect(() => {
        if (paginatedRows.length === 0) return;

        requestAnimationFrame(() => {
            const newWidths = columnsFormatted.map((column) => {
                const headerElement = document.getElementById(`header-${column.field}`);
                const headerWidth = headerElement ? headerElement.offsetWidth : 0;

                const cellWidths = paginatedRows.map(row => {
                    const cell = document.getElementById(`cell-${column.field}-${row.id}`);
                    return cell ? cell.offsetWidth : 0;
                });

                return Math.max(headerWidth, ...cellWidths);
            });

            // Utilisation d'une transition en douceur
            setColumnWidths((prevWidths) =>
                newWidths.map((newWidth, index) =>
                    prevWidths[index] !== undefined ? Math.max(prevWidths[index], newWidth) : newWidth
                )
            );
        });

    }, [paginatedRows, columnsFormatted]);




    useEffect(() => {
        if (visible) return
        if (columnWidths && columnWidths.length === columnsFormatted.length) {
            setTimeout(() => setVisible(true), 200)
        }
    }, [columnWidths])
    const cancelDelete = () => {
        setConfirmDeleteModalOpen(false)
        setDeletedRow(undefined)
    }
    const onChangeFieldInput = (field: string, value: string) => {
        const row = idInEditMode
        row[field] = value
        setIdInEditMode(row)
    }
    const handleSave = (e: any) => {
        if (updateRow) {
            updateRow(idInEditMode).then((r) => {
                if (r) {
                    setData((prevState) => {
                        const actualData = [...prevState] // Copie du tableau
                        const rowIndex = actualData.findIndex((row) => row.id === idInEditMode.id)
                        if (rowIndex !== -1) {
                            actualData[rowIndex] = {...idInEditMode} // Copie de l'objet pour éviter la mutation
                        }
                        return actualData
                    })
                    setIdInEditMode(null)
                }
            })
        }
    }

    return (
        <>
            {confirmDeleteModalOpen && deletedRow != undefined &&
                <FModal
                    className="bg-white w-2/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl"
                    canClose onClose={cancelDelete} title={"Confirmation"}>
                    <div className={"w-full flex flex-col mt-4"}>
                        <p className="text-xl">Êtes-vous sûr de
                            supprimer {returnFirstNonEmptyIdentifier(deletedRow)}</p>
                        {confirmDeleteText &&
                            <p className="p-5 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{confirmDeleteText}</p>
                        }
                    </div>
                    <div className={"w-full flex gap-2 mt-5 mb-5 "}>
                        <Button text={"Annuler"} onClick={cancelDelete}/>
                        <Button text={"Valider"} onClick={confirmDelete}/>
                    </div>
                </FModal>
            }
            <div className={`flex-grow h-[80%] max-h-full flex flex-col bg-fab_congress-blue-100 bg-opacity-95`}>
                {/* Barre de recherche */}
                <div className="w-full h-12 flex items-center justify-start gap-10">
                    <select
                        value={rowsPerPage}
                        onChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
                        className="border p-2 rounded w-1/6"
                    >
                        {[5, 10, 20, 50].map((option) => (
                            <option key={option} value={option}>
                                {option} lignes par page
                            </option>
                        ))}
                    </select>
                    <Button
                        className={"w-1/6 !m-0"}
                        leftIcon={<RestartAlt/>}
                        text={"Réinitialiser le tri"}
                        fullStyle
                        onClick={resetSorting}
                    />
                </div>

                <span>{totalRows} résultat(s)</span>
                {/* Table */}
                {sortedData.length > 0 && columnsFormatted.length > 0 ? (
                    <div
                        className={`h-[75%] w-full overflow-scroll transition-opacity duration-200 ${visible ? 'opacity-100' : 'opacity-0'}`}>
                        {/* HEADER */}
                        <div
                            className="sticky top-0 bg-gray-100 z-10 grid grid-flow-col"
                            style={{gridTemplateColumns: `repeat(${columnsFormatted.length}, 1fr)`}}
                        >
                            {columnsFormatted.map((column, index) => (
                                <div
                                    id={`header-${column.field}`} // Identifiant unique pour chaque header
                                    onClick={(e: any) => handleSort(e, column.field)}
                                    key={column.field}
                                    className={`duration-1000 whitespace-nowrap border border-gray-200 bg-white px-2 py-2 text-left cursor-pointer flex items-center justify-between font-bold ${
                                        index === columnsFormatted.length - 1 ? "w-full" : ""
                                    }`}
                                    style={{minWidth: columnWidths[index] || "auto"}}
                                >
                                    <span>{column.headerName}</span>
                                    {sortConfig && sortConfig?.column === column.field ? (
                                        sortConfig.direction === 'asc' ? <KeyboardArrowUp/> : <KeyboardArrowDown/>
                                    ) : (
                                        <DragHandle/>
                                    )}
                                </div>
                            ))}
                        </div>

                        {/* BODY */}
                        <div className="w-full flex flex-col">
                            {paginatedRows.map((row) => (
                                <div
                                    key={row.id}
                                    className="grid grid-flow-col  group "
                                    style={{gridTemplateColumns: `repeat(${columnsFormatted.length}, 1fr)`}}
                                >
                                    {columnsFormatted.map((column, index) => (
                                        <div
                                            id={`cell-${column.field}-${row.id}`} // Identifiant unique pour chaque cellule
                                            key={column.field}
                                            className={`duration-1000 group-hover:bg-fab_congress-blue-400 cursor-pointer border border-gray-200 flex items-center px-2 py-2 min-h-[40px] whitespace-nowrap ${
                                                index === columnsFormatted.length - 1 ? "w-full" : ""
                                            }`}
                                            onClick={(e) => showMoreClick(e, row.id)}
                                            style={{minWidth: columnWidths[index] || "auto"}}
                                        >
                                            {
                                                idInEditMode && idInEditMode.id === row.id && column.editable ?
                                                    column.type === 'boolean' &&
                                                    <FCheckBox state={idInEditMode[column.field]}
                                                        onChange={ (e) => {
                                                            setIdInEditMode((prevState: any) => {
                                                                return {
                                                                    ...prevState,
                                                                    [column.field]: !prevState[column.field],
                                                                }
                                                            })
                                                        }}
                                                    /> ||
                                                    column.type === 'singleSelect' &&
                                                    <FAutoComplete className="w-full p-0" data={column.valueOptions}
                                                                   valueId={row[column.field]}
                                                                   key={`${column.field}-${row.id}`}
                                                                   onSelectionChange={function (params: OnSelectionChangeParams<Identifiable>): void {
                                                                       setIdInEditMode((prevState: any) => {
                                                                           return {
                                                                               ...prevState,
                                                                               [column.field]: params?.newValue?.id ?? prevState.id
                                                                           }
                                                                       })
                                                                   }}/> ||
                                                    <TextInput defaultValue={idInEditMode[column.field]}
                                                               onChange={(e) => onChangeFieldInput(column.field, e.currentTarget.value)}/>
                                                    : <>{column.type === 'date' && DateToDateHours(row[column.field]) ||
                                                        column.type === 'dateTime' && DateToDate(row[column.field]) ||
                                                        column.type === 'string' &&
                                                        <span>{row[column.field]}</span> ||
                                                        column.type === 'singleSelect' &&
                                                        <p>{column.valueOptions?.find((e: any) => e.id === row[column.field])?.label ?? "N/A"}</p> ||
                                                        column.type === 'boolean' &&
                                                        <>{row[column.field] ? <Check /> : <CancelIcon/>}</> ||
                                                        column.renderCell && column.renderCell(row) ||
                                                        row[column.field]}</>
                                            }
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <p className="text-center">Aucune donnée disponible</p>
                )}
                {/* Pagination */}
                {paginatedRows && paginatedRows.length > 0 &&
                    <>
                        <div className="flex h-12 justify-between items-center w-1/3 m-auto">
                            {/* Bouton "Première page" */}
                            <button
                                onClick={() => handleSetCurrentPage(1)}
                                className=" fab_pagination_button"
                                disabled={currentPage === 1}
                            >
                                &laquo; {/* Flèche gauche pour la première page */}
                            </button>

                            {/* Bouton "Page précédente" */}
                            <button
                                onClick={() => handleSetCurrentPage(Math.max(currentPage - 1, 1))}
                                className=" fab_pagination_button"
                                disabled={currentPage === 1}
                            >
                                &#8249; {/* Flèche gauche */}
                            </button>

                            {/* Numéros de pages */}
                            <span className="flex items-center space-x-2">
          {currentPage > 2 && (
              <>
                  <button onClick={() => handleSetCurrentPage(1)}
                          className="cursor-pointer fab_pagination_button !bg-white !text-black !shadow-none scale-75"
                  >
                      1
                  </button>
                  <span className="text-gray-500">...</span>
              </>
          )}

                                {/* Pages numérotées */}
                                {currentPage > 1 && (
                                    <button
                                        onClick={() => handleSetCurrentPage(currentPage - 1)}
                                        className="cursor-pointer fab_pagination_button !bg-white !text-black !shadow-none scale-75"
                                    >
                                        {currentPage - 1}
                                    </button>
                                )}

                                <button
                                    className="font-bold cursor-pointer fab_pagination_button">{currentPage}</button>

                                {currentPage < totalPages && (
                                    <button
                                        onClick={() => handleSetCurrentPage(currentPage + 1)}
                                        className="cursor-pointer fab_pagination_button !bg-white !text-black scale-75"
                                    >
                                        {currentPage + 1}
                                    </button>
                                )}

                                {currentPage < totalPages - 1 && (
                                    <>
                                        <span className="text-gray-500">...</span>
                                        <button
                                            onClick={() => handleSetCurrentPage(totalPages)}
                                            className="cursor-pointer fab_pagination_button !bg-white !text-black !shadow-none scale-75"

                                        >
                                            {totalPages}
                                        </button>
                                    </>
                                )}
        </span>

                            {/* Bouton "Page suivante" */}
                            <button
                                onClick={() => handleSetCurrentPage(Math.min(currentPage + 1, totalPages))}
                                className="fab_pagination_button"
                                disabled={currentPage === totalPages}
                            >
                                &#8250; {/* Flèche droite */}
                            </button>

                            {/* Bouton "Dernière page" */}
                            <button
                                onClick={() => handleSetCurrentPage(totalPages)}
                                className="cursor-pointer fab_pagination_button !bg-white !text-black !shadow-none scale-75"
                                disabled={currentPage === totalPages}
                            >
                                &raquo; {/* Flèche droite pour la dernière page */}
                            </button>
                        </div>
                    </>}
            </div>
        </>
    )
}
