import {FModal} from "../../../ui/elements/FModal";
import React, {FC, useState} from "react";
import {IFModal} from "../../../ui/interfaces/elements/IFModal";
import {ICompany} from "../../../../interfaces/ICompany";
import {Field, FormGenerator} from "../../../ui/elements/FabForm"
import {useGlobalApi} from "../../../../contexts/GlobalApiProvider"

export const CompanyEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const {companiesApi: {addItem: addCompany, loading, error, resetApiError}} = useGlobalApi()
    const handleFormSubmit = (data: any) => {
        const newCompany: ICompany = {
            id: 0,
            label: data.label,
            Sites: [],
            code: null,
            createdAt: new Date(),
            updatedAt: new Date()
        }
        addCompany(newCompany).then((r) => {
            if (r && setModalOpen) setModalOpen(false)
            }
        )
    }
    const fields: Field[] = [
        {label: 'Nom de la société', name: 'label', type: 'text', required: true},
    ]
    return(<FModal className="fab_editor_modal" canClose onClose={onClose} title={"Ajouter une société"}>
        <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
            <FormGenerator loading={loading} fields={fields} onSubmit={handleFormSubmit}
                           apiError={error} resetApiError={resetApiError}/>
        </div>
    </FModal>
)

}