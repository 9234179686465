export const DateToDate = (date?: string) => {
    if (!date || date === '') {
        return null
    }
    const dateTyped = new Date(date)
    const dateParts = [
        dateTyped.getDate().toString().padStart(2, '0'),
        (dateTyped.getMonth() + 1).toString().padStart(2, '0'), // Les mois commencent à 0, on ajoute 1
        dateTyped.getFullYear()
    ]

    if (Number.isNaN(dateTyped.getTime())) {
        return ''
    }

    return `${dateParts.join('/')}`
}
