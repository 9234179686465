import React, {useEffect, useState} from "react";
import {
    GridRowModesModel,
} from '@mui/x-data-grid';
import {CompanyEditor} from "./editors/CompanyEditor";
import {FDataGrid} from "../../ui/elements/FDataGrid";
import {TablePageLayout} from "../layout/TablePageLayout"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
export const CompaniesPage = () => {
    const {companiesApi: {forceRefresh, data: companies, loading, error, resetApiError,updateItem: updateCompany,deleteItem: deleteCompany,refreshItems: refreshCompanies}} = useGlobalApi()
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const columns: any[] = [
        { field: 'label', headerName: 'Nom', type: 'string', width: 330, editable:true}
    ]
    const onCloseModal = () => {
        setModalOpen(false)
    }
    const openModal = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!modalOpen) setModalOpen(true);
    }
    useEffect(() => {
        if (!modalOpen) refreshCompanies().then();
    }, [modalOpen]);
    return (
        <>
            <TablePageLayout
                title={"SOCIÉTÉ"}
                Editor={CompanyEditor}
                forceRefreshData={forceRefresh}
                errors={error}
                resetError={resetApiError}
                loadingReference={loading}
                isFeminineLabel
            >
                <FDataGrid
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    updateRow={updateCompany}
                    deleteRow={deleteCompany}
                    rows={companies}
                    loading={loading}
                    columns={columns}
                />
            </TablePageLayout>
        </>
    )
}