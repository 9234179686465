import {Check, HourglassEmpty, Inventory, LocalShipping, QuestionMark, Rule, TapAndPlay} from "@mui/icons-material"
import React from "react"

export const statusCodeToString = (code: string)=> {
    switch (code) {
        case "NSTA" :
            return "En attente"
        case "STAR" :
            return "En cours"
        case "DONE" :
            return "Clôturé"
        case "ECAR" :
            return "Ecarts"
        case "PROD" :
            return "Produit"
        case "EXPE" :
            return "Expedié"
        case "DISP" :
            return "Disponible"
        default :
            return "Inconnu"
    }
}