import React, {FC} from "react"
import {IFModal} from "../../../ui/interfaces/elements/IFModal"
import {FModal} from "../../../ui/elements/FModal"
import {ProductCategory} from "../../../../interfaces/Product"
import {Field, FormGenerator} from "../../../ui/elements/FabForm"
import {useGlobalApi} from "../../../../contexts/GlobalApiProvider"

export const ProductCategoryEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const {
        productCategoriesApi: {addItem: addProductCategory, loading, error, resetApiError}
    } = useGlobalApi()
    const fields: Field[] = [
        {label: 'Nom de la catégorie', name: 'label', type: 'text', required: true},
    ]
    const onValidAddProductCategory = (data: any) => {
        const newCategory: ProductCategory = {
            id: 0,
            label: data.label,
            code: null
        }
        addProductCategory(newCategory).then((r) => {
            if (r && setModalOpen) setModalOpen(false)
            }
        )
    }
    return (
        <FModal title={"Ajout d'une catégorie de produit"}
                className="fab_editor_modal"
                canClose onClose={onClose}>
            <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
                <FormGenerator loading={loading} fields={fields} onSubmit={onValidAddProductCategory}
                               apiError={error} resetApiError={resetApiError}/>
            </div>
        </FModal>)
}
