import React, {useEffect, useState} from "react"
import {GridColDef, GridRowModesModel} from "@mui/x-data-grid"
import {FabOrder, FabOrderLine} from "../../../interfaces/FabOrder"
import {FDataGrid, IFDataGrid} from "../../ui/elements/FDataGrid"
import {Check, HourglassEmpty, QuestionMark, Rule, TapAndPlay} from "@mui/icons-material"
import {ShippingFabOrderEditor} from "../editors/ShippingFabOrderEditor"
import {useNavigate} from "react-router-dom"
import {FCheckBox} from "../../ui/inputs/FCheckBox"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {TablePageLayout} from "../../base/layout/TablePageLayout"
import {formatDate} from "../../../api/static_vars"
import {statusCodeToString} from "../../ui/utils/status_code_to_string"

export const ShippingPage = () => {
    const navigate = useNavigate()
    const {
        sabcShippingFabOrderApi: {
            resetApiError,
            data: orders,
            loading: loading,
            forceRefresh: forceRefreshOrders,
            error,
            deleteItem: deleteFabOrder
        },
        customersApi: {data: customers, getItemById: getCustomer}
    } = useGlobalApi()
    const [dataLoading, setDataLoading] = React.useState<boolean>(loading)
    const [dataFiltered, setDataFiltered] = React.useState<FabOrder[]>(orders)
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [filterStates, setFilterStates] = React.useState({
        notStarted: true,
        progress: true,
        closed: true,
        gap: true
    })
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])
    useEffect(() => {
        forceRefreshOrders()
    }, [])
    useEffect(() => {
        setColumns(([
            {field: 'internalOrderId', headerName: 'Identifiant', editable: true, width: 200},
            {
                field: 'statusCode', headerName: 'Statut', editable: true, width: 300, renderCell: (params: any) => {
                    switch (params.statusCode) {
                        case "NSTA" :
                            return <span className={"font-bold"}><HourglassEmpty/>En attente</span>
                        case "STAR" :
                            return <span className={"font-bold"}><TapAndPlay/>En cours</span>
                        case "DONE" :
                            return <span className={"font-bold"}><Check/>Clôturé</span>
                        case "ECAR" :
                            return <span className={"font-bold"}><Rule/>Ecarts</span>
                        default :
                            return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                    }
                },
                renderHeader: (params: any) => {
                    return statusCodeToString(params.statusCode)
                }
            },
            {
                field: 'executionDate',
                headerName: "Date d'expédition",
                type: 'dateTime',
                width: 200
            },
            {
                field: 'closingDate',
                headerName: 'Date de clôture',
                type: 'date',
                width: 200
            },
            {
                field: 'customerId', headerName: 'Client', editable: true, width: 550,renderHeader: (params: any) => {
                    if (params.Lines.length == 0) return "N/A"
                    return getCustomer(params.Lines[0].customerId)?.label
                }, renderCell: (params: any) => {
                    if (params.Lines.length == 0) return "N/A"
                    return getCustomer(params.Lines[0].customerId)?.label
                },
            },
            {
                field: 'batch',
                headerName: 'Lot(s)',
                editable: false,
                width: 150,
                renderCell: (params: any) => {
                    const lines = params.Lines
                    if (lines.length === 0) return "N/A"
                    const batches = lines.map((line: FabOrderLine) => line.batch).filter((batch: string) => batch != null)
                    const batchesString = batches.length > 0 ? batches.join(" | ") : "N/A"
                    return (
                        <span
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                position: 'relative',
                                display: 'inline-block',
                                maxWidth: '100%'
                            }}
                            title={batchesString}
                        >
                                {batchesString}
                            </span>
                    )
                },
                renderHeader: (params: any) => {
                    const lines = params.Lines
                    if (lines.length === 0) return ""
                    const batches = lines.map((line: FabOrderLine) => line.batch).filter((batch: string) => batch != null)
                    return batches.length > 0 ? batches.join(" | ") : ""
                }
            },
        ]))
    }, [customers])
    useEffect(() => {
        setDataLoading(loading)
    }, [loading])
    const toggleNotStarted = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, notStarted: !prevState.notStarted}))
    }
    const toggleInProgress = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, progress: !prevState.progress}))
    }
    const toggleDone = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, closed: !prevState.closed}))
    }
    const toggleGap = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, gap: !prevState.gap}))
    }
    const showDetails = (id: number) => {
        navigate(`${id}`)
    }
    useEffect(() => {
        let filteredData: FabOrder[] = []
        if (filterStates.notStarted) {
            filteredData.push(...orders.filter((value) => value.statusCode == "NSTA"))
        }
        if (filterStates.progress) {
            filteredData.push(...orders.filter((value) => value.statusCode == "STAR"))
        }
        if (filterStates.closed) {
            filteredData.push(...orders.filter((value) => value.statusCode == "DONE"))
        }
        if (filterStates.gap) {
            filteredData.push(...orders.filter((value) => value.statusCode == "ECAR"))
        }
        setDataFiltered(filteredData)
        setDataLoading(false)
    }, [filterStates, orders])
    const table: IFDataGrid = {
        rowModesModel: rowModesModel,
        setRowModesModel: setRowModesModel,
        rows: dataFiltered,
        loading: dataLoading,
        showMore: showDetails,
        deleteRow: deleteFabOrder,
        columns: columns
    }
    return (<>
        <TablePageLayout
            title={"ORDRE DE DISTRIBUTION"}
            Editor={ShippingFabOrderEditor}
            forceRefreshData={forceRefreshOrders}
            errors={error}
            resetError={resetApiError}
            loadingReference={loading}
            table={table}
            canExport
            checkboxs={<>
                <FCheckBox
                    label={'En attente'}
                    onChange={toggleNotStarted}
                    state={filterStates.notStarted}
                />
                <FCheckBox
                    label={'En cours'}
                    onChange={toggleInProgress}
                    state={filterStates.progress}
                />
                <FCheckBox
                    label={'Clôturés'}
                    onChange={toggleDone}
                    state={filterStates.closed}
                />
                <FCheckBox
                    label={'En Ecart'}
                    onChange={toggleGap}
                    state={filterStates.gap}
                />
            </>}
        >
        </TablePageLayout>
    </>)
}