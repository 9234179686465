import {FDataGrid} from "../../ui/elements/FDataGrid"
import React, {useEffect, useState} from "react"
import {GridColDef, GridRowModesModel, GridRowParams} from "@mui/x-data-grid"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {TablePageLayout} from "../../base/layout/TablePageLayout"
import {Check, HourglassEmpty, Inventory, LocalShipping, QuestionMark, Rule, TapAndPlay} from "@mui/icons-material"
import {Button} from "../../ui/inputs/Button"
import {useNavigate} from "react-router-dom"
import {Field} from "../../ui/elements/FabForm"
import {statusCodeToString} from "../../ui/utils/status_code_to_string"

export const SiteMovementsPageSabc = () => {
    const navigate = useNavigate()
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
    const {
        fabOrdersApi: {getItemById: getOrder, forceRefresh: forceRefreshOrders, data: orders},
        siteMovementsApi: {forceRefresh, data: movements, error, resetApiError, loading},
        productReferencesApi: {forceRefresh: forceRefreshProducts, getItemById: getProductReference}
    } = useGlobalApi()
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])
    const customForceRefresh = () => {
        forceRefreshProducts()
        forceRefreshOrders()
        forceRefresh()
    }
    useEffect(() => {
        customForceRefresh()
    }, [])
    useEffect(() => {
        if (orders.length == 0) return
        if (movements.length == 0) return
        setColumns([
            {
                field: 'Tag',
                headerName: 'EPC',
                width: 200,
                renderCell: (params: any) => params.Tag ? <p>{params.Tag.rfidValue}</p> : <p>''</p>,
                renderHeader: (params: any) => params.Tag ? params.Tag.rfidValue : ''
            },
            {
                field: 'tagStatusCode', headerName: 'Statut Contenant', width: 200, renderCell: (params: any) => {
                    if (params.tagStatusCode === null) return <span></span>
                    switch (params.tagStatusCode) {
                        case "PROD" :
                            return <span className={"font-bold"}><Inventory/>Produit</span>
                        case "EXPE" :
                            return <span className={"font-bold"}><LocalShipping/>Expedié</span>
                        case "DISP" :
                            return <span className={"font-bold"}><HourglassEmpty/>Disponible</span>
                        default :
                            return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                    }
                },
                renderHeader: (params: any)=> {
                    if (params.Tag === null) return ""
                    return statusCodeToString(params.tagStatusCode)
                }
            },
            {
                field: 'orderStatusCode', headerName: 'Statut Ordre', width: 200, renderCell: (params: any) => {
                    switch (params.orderStatusCode) {
                        case "NSTA" :
                            return <span className={"font-bold"}><HourglassEmpty/>En attente</span>
                        case "STAR" :
                            return <span className={"font-bold"}><TapAndPlay/> En cours</span>
                        case "DONE" :
                            return <span className={"font-bold"}><Check/>Clôturé</span>
                        case "ECAR" :
                            return <span className={"font-bold"}><Rule/>Ecarts</span>
                        case null:
                            return ""
                        default :
                            return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                    }
                },
                renderHeader: (params: any)=> {
                    return statusCodeToString(params.orderStatusCode)
                }
            },
            {
                field: 'productReferenceId',
                headerName: 'Produit',
                editable: true,
                width: 300,
                renderCell: (params: any) => {
                    const label = getProductReference(params.productReferenceId)?.label
                    return label ?? "N/A"
                }
            },
            {
                field: 'batch',
                headerName: 'Lot',
                type: 'string',
                editable: false,
                width: 150
            },
            {
                field: 'action',
                headerName: 'Ordre',
                editable: true,
                width: 300,
                renderCell: (params: any) => {
                    const order = getOrder(params.fabOrderId)
                    return <Button
                        text={order?.internalOrderId ?? "N/A"}
                        disabled={!order}
                        onClick={() => {
                            if (order?.internalOrderId.startsWith('OD')) {
                                navigate(`/dashboard/shipping/${params.fabOrderId}`)
                            } else if (order?.internalOrderId.startsWith('OF')) {
                                navigate(`/dashboard/production/${params.fabOrderId}`)
                            }

                        }}
                    />
                },
                renderHeader: (params: any)=> {
                    const order = getOrder(params.fabOrderId)
                    return order?.internalOrderId ?? "N/A"
                }
            },
            {
                field: 'User',
                headerName: 'Utilisateur',
                width: 280,
                renderCell: (params: any) => params ? <p>{params.User.mobileLogin}</p>: <p>''</p>,
                renderHeader: (params:any )=> params ? params.User.mobileLogin : ''
            },
        ])
    }, [movements, orders])
    const table = {
        disableEdit: true,
        rowModesModel: rowModesModel,
        setRowModesModel: setRowModesModel,
        rows: movements,
        loading: loading,
        columns: columns
    }
    return (
        <>
            <TablePageLayout
                title={"MOUVEMENTS"}
                forceRefreshData={customForceRefresh}
                errors={error}
                resetError={resetApiError}
                loadingReference={loading}
                table={table}
                canExport
            >
            </TablePageLayout>
        </>
    )
}