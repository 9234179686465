import React, {useEffect, useState} from "react"
import {GridColDef, GridRowModesModel} from "@mui/x-data-grid"
import {FabOrder} from "../../../interfaces/FabOrder"
import {FDataGrid, IFDataGrid} from "../../ui/elements/FDataGrid"
import {
    Check,
    HourglassEmpty,
    QuestionMark,
    TapAndPlay
} from "@mui/icons-material"
import {ProductionFabOrderEditor} from "../editors/ProductionFabOrderEditor"
import {FCheckBox} from "../../ui/inputs/FCheckBox"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {TablePageLayout} from "../../base/layout/TablePageLayout"
import {formatDate} from "../../../api/static_vars"
import {useNavigate} from "react-router-dom"
import {statusCodeToString} from "../../ui/utils/status_code_to_string"

export const ProductionPage = () => {
    const {
        productReferencesApi: {data: productReferences, getItemById: getProductReference},
        sabcProductionFabOrderApi: {
            data: orders,
            loading,
            error,
            resetApiError,
            refreshItems: refreshOrders,
            forceRefresh: forceRefreshOrders,
            deleteItem: deleteFabOrder
        }
    } = useGlobalApi()
    const [dataLoading, setDataLoading] = React.useState<boolean>(loading)
    const [dataFiltered, setDataFiltered] = React.useState<FabOrder[]>(orders)
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const navigate = useNavigate()
    const [filterStates, setFilterStates] = React.useState({
        notStarted: true,
        progress: true,
        closed: true,
    })
    const [columns, setColumns] = useState<GridColDef[]>([])
    useEffect(() => {
        forceRefreshOrders()
    }, [])
    useEffect(() => {
        setColumns(
            [
                {field: 'internalOrderId', headerName: 'Identifiant', editable: true, width: 200},
                {
                    field: 'statusCode',
                    headerName: 'Statut',
                    editable: true,
                    width: 300,
                    renderCell: (params: any) => {
                        switch (params.statusCode) {
                            case "NSTA" :
                                return <span className={"font-bold"}><HourglassEmpty/>En attente</span>
                            case "STAR" :
                                return <span className={"font-bold"}><TapAndPlay/> En cours</span>
                            case "DONE" :
                                return <span className={"font-bold"}><Check/>Clôturé</span>
                            default :
                                return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                        }
                    },
                    renderHeader: (params: any) => {
                        return statusCodeToString(params.statusCode)
                    }
                },
                {
                    field: 'executionDate',
                    headerName: 'Date de production',
                    type: 'dateTime',
                    width: 200
                },
                {
                    field: 'closingDate',
                    headerName: 'Date de clôture',
                    type: 'date',
                    width: 200,
                    valueGetter: (params) => {
                        if (params == null) return null
                        else return formatDate(params)
                    }
                },
                {
                    field: 'quantity_state',
                    headerName: 'Etat Quantité',
                    editable: true,
                    width: 150,
                    renderCell: (params: any) => {
                        if (params.Lines.length == 0) return "N/A"
                        return `${params.Lines[0].quantityDone}/${params.Lines[0].quantity}`
                    }
                },
                {
                    field: 'batch_header',
                    headerName: 'Lot',
                    editable: false,
                    width: 150,
                    renderCell: (params: any) => {
                        if (params.Lines.length == 0) return "N/A"
                        return params.Lines[0].batch
                    },
                    renderHeader: (params: any) => {
                        return params.Lines[0]?.batch ?? ""
                    }
                },
                {
                    field: 'product',
                    headerName: 'Référence produit',
                    editable: false,
                    width: 250,
                    renderCell: (params: any) => {
                        if (params.Lines.length == 0) return "N/A"
                        return getProductReference(params.Lines[0].productReferenceId)?.label
                    },
                    renderHeader: (params: any) => {
                        if (params.Lines.length == 0) return ""
                        return getProductReference(params.Lines[0].productReferenceId)?.label
                    }
                }
            ]
        )
    }, [productReferences])
    const toggleNotStarted = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, notStarted: !prevState.notStarted}))
    }
    const toggleInProgress = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, progress: !prevState.progress}))
    }
    const toggleDone = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, closed: !prevState.closed}))
    }
    const showDetails = (id: number) => {
        navigate(`${id}`)
    }
    useEffect(() => {
        forceRefreshOrders()
    },[])
    useEffect(() => {
        if (productReferences.length <= 0) return
        let filteredData: FabOrder[] = []
        if (filterStates.notStarted) {
            filteredData.push(...orders.filter((value) => value.Lines[0]?.quantityDone == 0 && !value.closed))
        }
        if (filterStates.progress) {
            filteredData.push(...orders.filter((value) => value.Lines[0]?.quantityDone > 0 && !value.closed))
        }
        if (filterStates.closed) {
            filteredData.push(...orders.filter((value) => value.closed))
        }
        setDataFiltered(filteredData)
        setDataLoading(false)
    }, [filterStates, orders, productReferences])
    const table: IFDataGrid = {
        rowModesModel:rowModesModel,
        setRowModesModel:setRowModesModel,
        rows:dataFiltered,
        loading:dataLoading,
        showMore:showDetails,
        deleteRow:deleteFabOrder,
        columns:columns
    }
    return (<>
        <TablePageLayout
            title={"ORDRE DE FABRICATION"}
            Editor={ProductionFabOrderEditor}
            forceRefreshData={forceRefreshOrders}
            errors={error}
            resetError={resetApiError}
            loadingReference={loading}
            table={table}
            canExport
            checkboxs={<>
                <FCheckBox
                    label={'En attente'}
                    onChange={toggleNotStarted}
                    state={filterStates.notStarted}
                />
                <FCheckBox
                    label={'En cours'}
                    onChange={toggleInProgress}
                    state={filterStates.progress}
                />
                <FCheckBox
                    label={'Clôturés'}
                    onChange={toggleDone}
                    state={filterStates.closed}
                />
            </>}
        />
    </>)
}