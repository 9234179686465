import React, {useEffect, useState} from "react"
import {GridColDef, GridRowModesModel, GridRowParams} from '@mui/x-data-grid'
import {FDataGrid, IFDataGrid} from '../../ui/elements/FDataGrid'
import {Tag} from "../../../interfaces/Tag"
import {HourglassEmpty, Inventory, LocalShipping, QuestionMark} from "@mui/icons-material"
import {FCheckBox} from "../../ui/inputs/FCheckBox"
import {TablePageLayout} from "../../base/layout/TablePageLayout"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {Button} from "../../ui/inputs/Button"
import {useNavigate} from "react-router-dom"
import {Field} from "../../ui/elements/FabForm"
import {statusCodeToString} from "../../ui/utils/status_code_to_string"
import {FabOrderLine, FabOrderLineMovement} from "../../../interfaces/FabOrder"

export const ContainerList = () => {
    const {
        taggedContainersApi: {
            data: containers,
            loading,
            error,
            resetApiError,
            refreshItems: refreshContainers,
            forceRefresh: forceRefreshContainers
        },
        customersApi: {data: customers, getItemById: getCustomer},
        productReferencesApi: {data: productReferences, getItemById: getProductReference},
        fabOrdersApi: {data: orders, getItemById: getOrder, forceRefresh: forceRefreshOrders},
        siteMovementsApi: {data: siteMovements, forceRefresh: forceRefreshMovements}
    } = useGlobalApi()
    const [dataLoading, setDataLoading] = React.useState<boolean>(loading)
    const [dataFiltered, setDataFiltered] = React.useState<Tag[]>(containers)
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [filterStates, setFilterStates] = React.useState({
        all: true,
        case: false,
        pallet: false,
        waiting: true,
        production: true,
        sends: true,
    })
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])
    const navigate = useNavigate()
    useEffect(() => {
        forceRefreshOrders()
        forceRefreshContainers()
        forceRefreshMovements()
    }, [])
    useEffect(() => {
        if (productReferences.length == 0) return
        if (containers.length == 0) return
        if (orders.length == 0) return
        setColumns([
            {
                field: 'type', headerName: 'Type', editable: false, width: 50, renderCell: (params: any) => {
                    if (params.rfidValue.startsWith('PA')) {
                        return <span title={"Palette"}
                                     className="material-symbols-outlined m-auto hover:cursor-help">pallet</span>
                    } else if (params.rfidValue.startsWith('CA')) {
                        return <span title={"Casier"}
                                     className="material-symbols-outlined m-auto cursor-help">package_2</span>
                    }
                }, renderHeader: (params: any) => (
                    params.rfidValue.startsWith('CA') ? "Casier" : "Palette"
                )

            },
            {field: "rfidValue", headerName: 'Identifiant', editable: true, width: 300},
            {
                field: 'statusCode', headerName: 'Statut', editable: true, width: 250, renderCell: (params: any) => {
                    switch (params.statusCode) {
                        case "PROD" :
                            return <span className={"font-bold"}><Inventory/> Produit</span>
                        case "EXPE" :
                            return <span className={"font-bold"}><LocalShipping/> Expedié</span>
                        case "DISP" :
                            return <span className={"font-bold"}><HourglassEmpty/>Disponible</span>
                        default :
                            return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                    }
                },
                renderHeader: (params: any) => {
                    return statusCodeToString(params.statusCode)
                }
            },
            {
                field: 'productReferenceId',
                headerName: 'Produit',
                editable: true,
                width: 300,
                renderCell: (params: any) => {
                    const label = getProductReference(params.productReferenceId)?.label ?? "N/A"
                    return label ?? "N/A"
                }
            },
            {
                field: 'batch',
                headerName: 'Lot',
                editable: false,
                width: 150,
            },
            {
                field: 'customerId',
                headerName: 'Client',
                editable: true,
                width: 300,
                renderCell: (params: any) => {
                    const label = getCustomer(params.customerId)?.label
                    return label ?? "N/A"
                }
            },
            {
                field: 'fabOrderId',
                headerName: 'Ordre',
                editable: true,
                width: 150,
                renderCell: (params: any) => {
                    const label = getOrder(params.fabOrderId)?.internalOrderId
                        return <Button
                            text={label ?? "N/A"}
                            disabled={!label}
                            onClick={() => {
                                if (params.statusCode == "EXPE") {
                                    if (params.fabOrderId > 0) {
                                        navigate(`/dashboard/shipping/${params.fabOrderId}`)
                                    }
                                } else if (params.statusCode == "PROD") {
                                    if (params.fabOrderId > 0) {
                                        navigate(`/dashboard/production/${params.fabOrderId}`)
                                    }
                                }

                            }}
                        />
                },
                renderHeader: (params: any) => {
                    const label = getOrder(params.fabOrderId)?.internalOrderId
                    return label ?? "N/A"
                }
            }
        ])
    }, [productReferences, containers, orders])
    const [modalAddCustomerOpen, setModalAddCustomerOpen] = useState<boolean>(false)
    useEffect(() => {
        setDataLoading(loading)
    }, [loading])
    const allContainers = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, all: true, case: false, pallet: false}))
    }
    const allCases = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, all: false, case: !prevState.case}))
    }
    const allPallets = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, all: false, pallet: !prevState.pallet}))
    }
    const allWaiting = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, waiting: !prevState.waiting}))
    }
    const allProduce = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, production: !prevState.production}))
    }
    const allSends = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, sends: !prevState.sends}))
    }
    const [filterInputs, setFilterInputs] = useState<Field[]>([
        {
            label: 'Lot',
            type: 'text',
            required: false,
            name: 'batch',
            active: false,
            value: ""
        },
        {
            label: 'Client',
            type: 'autocomplete',
            required: false,
            data: customers,
            name: 'customerId',
            active: false,
            value: ""
        }
    ])
    const onChangeFilterInputOverload = (column: string, value: string) => {
        const newFilters = [...filterInputs]
        if (orders.length > 0) {
            const filterInputIndex = newFilters.findIndex(filter => filter.name === column)
            if (newFilters[filterInputIndex] != null)
            {
                if (value != "" || value != null) {
                    newFilters[filterInputIndex].active = true
                    newFilters[filterInputIndex].value = value
                } else {
                    newFilters[filterInputIndex].active = false
                    newFilters[filterInputIndex].value = undefined
                }
            }
            setFilterInputs(newFilters)
        }
    }
    useEffect(() => {
        let filteredData: Tag[] = []
        let containersData: Tag[] = []
        if (filterStates.all) {
            containersData = containers
        } else {
            if (filterStates.case) {
                containersData.push(...containers.filter((value) => value.rfidValue.startsWith('CA')))
            }
            if (filterStates.pallet) {
                containersData.push(...containers.filter((value) => value.rfidValue.startsWith('PA')))
            }
        }
        if (filterStates.waiting) {
            filteredData.push(...containersData.filter((value) => (value.statusCode == "DISP")))
        }
        if (filterStates.production) {
            filteredData.push(...containersData.filter((value) => (value.statusCode == "PROD")))
        }
        if (filterStates.sends) {
            filteredData.push(...containersData.filter((value) => (value.statusCode == "EXPE")))
        }
        filterInputs.forEach( filter => {
            if (filter.value != undefined && filter.value != "" && filter.active) {
                switch (filter.name) {
                    case 'batch':
                        filteredData = filteredData.filter(tag =>
                            tag.batch && tag.batch.toLowerCase().includes(filter.value!.toLowerCase())
                        )
                        break
                    case 'customerId':
                        filteredData = filteredData.filter(tag => tag.customerId === Number(filter.value!))
                        break
                }
            }
        })
        setDataFiltered(filteredData)
        setDataLoading(false)
    }, [filterStates, filterInputs, containers])

    useEffect(() => {
        if (!modalAddCustomerOpen) refreshContainers().then(r => r)
    }, [modalAddCustomerOpen])
    const table: IFDataGrid = {
        disableEdit: true,
        rowModesModel: rowModesModel,
        setRowModesModel: setRowModesModel,
        rows: dataFiltered,
        loading: dataLoading,
        columns: columns
    }
    return (
        <>
            <TablePageLayout
                title={"CASIERS ET PALETTES"}
                forceRefreshData={forceRefreshContainers}
                errors={error}
                resetError={resetApiError}
                loadingReference={loading}
                table={table}
                canExport
                filterInputs={filterInputs}
                onChangeFilterInputOverload={onChangeFilterInputOverload}
                checkboxs={<>
                    <FCheckBox
                        label={'Tout Types'}
                        onChange={allContainers}
                        state={filterStates.all}
                    />
                    <FCheckBox
                        label={'Casiers'}
                        onChange={allCases}
                        state={filterStates.case}
                    />
                    <FCheckBox
                        label={'Palettes'}
                        onChange={allPallets}
                        state={filterStates.pallet}
                    />
                    <div className="w-1 rounded-md h-1/2 bg-gray-400"/>
                    <FCheckBox
                        label={'Disponible'}
                        onChange={allWaiting}
                        state={filterStates.waiting}
                    />
                    <FCheckBox
                        label={'Produit'}
                        onChange={allProduce}
                        state={filterStates.production}
                    />
                    <FCheckBox
                        label={'Expedié'}
                        onChange={allSends}
                        state={filterStates.sends}
                    />
                </>}/>
        </>
    )
}