import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {FabOrder, FabOrderLine} from "../../../interfaces/FabOrder"
import {ArrowLeft, Check, HourglassEmpty, ImportExport, QuestionMark, Rule, TapAndPlay} from "@mui/icons-material"
import {Button} from "../../ui/inputs/Button"
import {FDataGrid} from "../../ui/elements/FDataGrid"
import {GridColDef, GridRowModesModel} from "@mui/x-data-grid"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {ArrowPathIcon} from "@heroicons/react/24/solid"
import Papa, {UnparseConfig} from "papaparse"
import {translateHeadersAndRows} from "../../ui/utils/translateHeadersAndRows"
import {statusCodeToString} from "../../ui/utils/status_code_to_string"

export const ShippingFabOrderViewer = () => {
    const {
        sabcShippingFabOrderApi: {data:orders, getItemById: getFabOrder, forceRefresh: forceRefreshShippingOrder, loading},
        productReferencesApi: {data: productReferences, getItemById: getProductReference},
        customersApi: {getItemById: getCustomer},
    } = useGlobalApi()
    const {id} = useParams();
    const navigate = useNavigate();
    const [fabOrder, setFabOrder] = useState<FabOrder | undefined>();
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [orderLines, setOrderLines] = useState<FabOrderLine[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    let config: UnparseConfig
    const showDetails = (id: number) => {
        navigate(`movements/${id}`)
    }

    useEffect(() => {
        setColumns(([
            {
                field: 'productReferenceId', headerName: 'Produit', editable: true, width: 500, renderCell: (params: any) => {
                    return getProductReference(params.productReferenceId)?.label ?? "N/A";
                },
            },
            {
                field: 'batch',
                headerName: 'Lot',
                editable: false,
                width: 150
            },
            {
                field: 'qty', headerName: 'État quantités', editable: true, width: 500, renderCell: (params: any) => {
                    return `${params.quantityDone} / ${params.quantity}`;
                },
            },
            {
                field: 'createdAt',
                headerName: 'Date de création',
                type: 'date',
                width: 200
            },
            {
                field: 'updatedAt',
                headerName: 'Date de mise à jour',
                type: 'date',
                width: 200
            }
        ]));
    }, [productReferences]);

    useEffect(() => {
        if (columns.length <= 0) return
        config = {
            quotes: false, //or array of booleans
            quoteChar: '"',
            escapeChar: '"',
            delimiter: ";",
            header: true,
            newline: "\r\n",
            skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
            columns: columns.map(col => col.field)  //or array of strings
        }
    }, [columns])

    useEffect(() => {
        let remoteFabOrder = getFabOrder(Number(id));
        if (remoteFabOrder == undefined) return;
        setFabOrder(remoteFabOrder);
        let lines = remoteFabOrder.Lines.slice(1);
        setOrderLines(lines);
    }, [orders]);
    const goBack = () => {
        window.history.back()
    };

    const exportViewer = () => {
        if (!fabOrder) return;
        let csvRows = []
        let csv1, csv2
        if (fabOrder) {
            const custColumns: GridColDef[] = [
                {
                    field: 'internalOrderId', headerName: "Numéro d'ordre", editable: true, width: 500
                },
                {
                    field: 'statusCode', headerName: "Statut Code", editable: true, width: 500, renderCell: (params: any) => {
                        return statusCodeToString(params.statusCode)
                    }
                },
                {
                    field: 'customerId', headerName: "Client", editable: true, width: 500,                     renderCell: (params: any) => {
                        if (params.Lines.length == 0) return "N/A"
                        return getCustomer(params.Lines[0].customerId)?.label ?? "N/A"
                    }
                },
                {
                    field: 'createdAt',
                    headerName: 'Date de création',
                    type: 'date',
                    width: 200
                },
                {
                    field: 'updatedAt',
                    headerName: 'Date de mise à jour',
                    type: 'date',
                    width: 200
                }
            ]
            const custColForConfig = custColumns.map(col => col.field)
            const { translatedRows, translatedHeaders } = translateHeadersAndRows(
                [fabOrder],
                custColumns
            )
            csv1 = Papa.unparse(translatedRows, {
                ...config,
                columns: translatedHeaders
            });
        }
        if (fabOrder.Lines.length > 0) {
            const sortedLine = fabOrder.Lines
            sortedLine.shift()
            const { translatedRows, translatedHeaders } = translateHeadersAndRows(
                fabOrder.Lines,
                columns
            );
            csv2 = Papa.unparse(translatedRows,  {
                ...config,
                columns: translatedHeaders
            });
        }
        // Générer le CSV avec les données traduites
        //const csv = Papa.unparse(csvRows, config);
        const finalCsv = `${csv1};\n${csv2}`
        console.log(finalCsv)
        const csvData = new Blob([String.fromCharCode(0xFEFF), finalCsv], { type: "text/csv;charset=utf-8;" });
        const csvURL = window.URL.createObjectURL(csvData);
        // Créer un lien de téléchargement temporaire
        const testLink = document.createElement("a");
        testLink.href = csvURL;
        testLink.setAttribute(
            "download",
            `${fabOrder.internalOrderId.toLowerCase()}_${new Date().toISOString()}.csv`
        );
        document.body.appendChild(testLink); // Ajoutez le lien au DOM pour Firefox
        testLink.click();
        document.body.removeChild(testLink); // Supprimez le lien après le clic
    }
    return (<div className="w-full h-full bg-fab_app_container flex flex-col">
        {
            fabOrder !== undefined ? <>
                    <div className="panel_page_entete">
                        <span className="text-2xl m-auto">{fabOrder.internalOrderId}</span>
                    </div>
                    <Button onClick={forceRefreshShippingOrder} loading={loading} className={"w-1/3"}
                            text={"Rafraîchir"}
                            leftIcon={<ArrowPathIcon/>}/>
                    <Button onClick={exportViewer} leftIcon={<ImportExport/>} className={"w-1/3"} text={`Exporter la page`} />
                    <div>
                        <div className="w-1/2 m-auto bg-fab_congress-blue-200 mt-10 shadow-md rounded-md border-2">

                            <div className="flex w-full gap-5">
                                <span className={"text-2xl m-auto w-1/2 text-right"}>Statut : </span>
                                {fabOrder.statusCode == "NSTA" &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><HourglassEmpty/>En attente</span>}
                                {fabOrder.statusCode == "STAR" &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><TapAndPlay/> En cours</span>}
                                {fabOrder.statusCode == "DONE" &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><Check/>Terminé</span>}
                                {fabOrder.statusCode == "ECAR" &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><Rule/>Ecarts</span>}
                                {fabOrder.statusCode == "" || fabOrder.statusCode == null &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><QuestionMark/>Inconnu</span>}
                            </div>

                            <div className="flex w-full gap-5">
                                <span className={"text-2xl m-auto w-1/2 text-right"}>Client / Destinataire :</span>
                                <span className={"text-2xl m-auto font-bold w-1/2 text-left"}>{getCustomer(fabOrder.Lines[0].customerId)?.label}</span>
                            </div>

                        </div>

                    </div>

                    <div className="w-full m-auto h-1/2 flex flex-col">
                        <FDataGrid
                            rowModesModel={rowModesModel}
                            setRowModesModel={setRowModesModel}
                            rows={orderLines}
                            loading={false}
                            columns={columns}
                            disableEdit
                            showMore={showDetails}
                        />

                    </div>
                </>
                :
                <>
                    <span>Undefined</span>
                </>
        }
        <Button text={"Retour"} onClick={goBack} leftIcon={<ArrowLeft/>}/>
    </div>);
};