import {IFModal} from "../../ui/interfaces/elements/IFModal"
import React, {FC, MouseEventHandler, useEffect, useState} from "react"
import {FabOrder} from "../../../interfaces/FabOrder"
import {useFabApp} from "../../../hooks/useFabApp"
import {FModal} from "../../ui/elements/FModal"
import {Button} from "../../ui/inputs/Button"
import {CheckCircleIcon, PlusCircleIcon, XCircleIcon} from "@heroicons/react/24/solid"
import {Field, FormGenerator} from "../../ui/elements/FabForm"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {ProductReference} from "../../../interfaces/Product"
import {Visibility} from "@mui/icons-material"
import {useNavigate} from "react-router-dom"

export const ProductionFabOrderEditor: FC<IFModal> = ({setModalOpen}) => {
    const {
        containerTypesApi: {data: containerTypes, getItemById: getContainerType},
        sabcProductionFabOrderApi: {loading, addItem: addFabOrder, error, resetApiError},
        productReferencesApi: {data: productReferences, getItemById: getProductReference}
    } = useGlobalApi()
    const {companyChosen, siteChosen} = useFabApp()
    const [creationDone, setCreationDone] = useState<boolean>(false)
    const [newFabOrder, setNewFabOrder] = useState<FabOrder>({} as FabOrder)
    const [confirmText, setConfirmText] = useState<string | null>(null)
    const [productReferencesFormated, setProductReferencesFormated] = useState<ProductReference[]>([])
    const [confirmData, setConfirmData] = useState<any | null>(null)
    const navigate = useNavigate()
    const newOrder = () => {
        setCreationDone(false)
    }
    useEffect(() => {
        let newTab = productReferences.map( ref => {
            const label = ref.label +
                " | " + getContainerType(ref.containerTypeId)?.label + " de " + ref.containerUnity
            const newRef = {...ref}
            newRef.label = label
            return newRef
        })
        setProductReferencesFormated(newTab)
    }, [productReferences])
    const fields: Field[] = [
        {label: 'Numéro : OF ', name: 'internalOrderId', type: 'text', required: true, needStartWith: 'OF'},
        {
            label: 'Réference produit',
            name: 'productReferenceId',
            type: 'autocomplete',
            required: true,
            data: productReferencesFormated
        },
        {label: 'Quantité', name: 'quantity', type: 'number', required: true},
        {label: 'Lot', name: 'batch', type: 'text', required: true},
        {label: 'Date de fabrication', name: 'executionDate', type: 'date', required: true, authorizeBeforeDay: false},
    ]
    const validOrder = (data: any) => {
        const fabOrderToSend: FabOrder = {
            id: 0,
            internalOrderId: data.internalOrderId,
            closed: false,
            siteId: siteChosen!.id,
            executionDate: data.executionDate,
            Infos: [],
            Lines: [{
                id: 0,
                quantity: data.quantity,
                quantityDone: 0,
                quantityContainer: data.quantityContainer,
                quantityContainerDone: 0,
                batch: data.batch,
                productCategoryId: 0,
                productReferenceId: data.productReferenceId,
                containerTypeId: 0,
                customerId: 0,
                fabOrderId: 0,
                Movements: []
            }]
        }
        fabOrderToSend.siteId = siteChosen?.id ?? 0
        addFabOrder(fabOrderToSend).then(r => {
            if (!r) return
            setNewFabOrder(r as FabOrder)
            setConfirmData(null)
            setCreationDone(true)
        })
    }
    const onClose: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (setModalOpen) {
            setModalOpen(false)
        }
        if (e.currentTarget.id.startsWith('order-')) {
            let id = e.currentTarget.id.replace('order-', '')
            navigate(id)
        }
    }
    const handleFormSubmit = (data: any) => {

        let product = getProductReference(data.productReferenceId)
        if (!product) return
        let container = getContainerType(product.containerTypeId)
        let containerQuantity = Math.ceil(data.quantity / product.containerUnity)
        let rest = data.quantity % product.containerUnity
        if (rest > 0) {
            data.quantityContainer = containerQuantity
            setConfirmData(data)
            setConfirmText(`Le nombre de produits n'est pas en adéquation avec son conditionnement. Il sera prévu ${containerQuantity} ${container!!.label}(s)`)
            return
        }

        validOrder(data)
    }
    const onValidConfirm = () => {
        setConfirmText(null)
        validOrder(confirmData)
    }
    const onCloseConfirm = () => {
        setConfirmData(null)
        setConfirmText(null)
    }

    return (
        <FModal
            className="fab_editor_modal h-2/3"
            canClose onClose={onClose} title={`Création d'un ordre de fabrication`}>
            <div className="w-full">
                <span>Société : <b>{companyChosen?.label}</b></span>
            </div>
            <div className="w-full">
                <span>Site : <b>{siteChosen?.label}</b></span>
            </div>
            {creationDone ? <div className={"w-full m-auto p-1 mt-10"}>
                    <span className={"text-2xl font-bold"}>{newFabOrder.internalOrderId} crée</span>
                    <Button onClick={onClose} leftIcon={<Visibility/>} loading={loading}
                            text={"Voir"}
                            id={`order-${newFabOrder.id}`}
                            className={"mt-2 mb-1 w-1/2"}/>
                    <Button onClick={newOrder} leftIcon={<PlusCircleIcon/>} loading={loading}
                            text={"Ajouter un ordre de fabrication"}
                            className={"mt-2 mb-1 w-1/2"}/>
                    <Button onClick={onClose} leftIcon={<XCircleIcon/>} loading={loading}
                            text={"Fermer"}
                            className={"mt-2 mb-1 w-1/2"}/>
                </div>
                :
                <FormGenerator loading={loading} fields={fields} onSubmit={handleFormSubmit} apiError={error} resetApiError={resetApiError}/>
            }
            {
                confirmText &&
                <FModal
                    className="bg-white w-3/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl !mt-5"
                    canClose title={"Attention"}>
                    <p className="p-2 m-2 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{confirmText}</p>
                    <div className="w-full flex">
                        <Button onClick={onCloseConfirm} leftIcon={<XCircleIcon/>} loading={loading}
                                text={"Annuler"}
                                className={"mt-2 mb-1 w-1/3"}/>
                        <Button onClick={onValidConfirm} leftIcon={<CheckCircleIcon/>} loading={loading}
                                text={"Valider"}
                                className={"mt-2 mb-1 w-1/3"}/>
                    </div>
                </FModal>
            }
        </FModal>)
}