import {ISite} from "../../../../interfaces/ISite"
import {Button} from "../../../ui/inputs/Button"
import React, {useEffect, useRef, useState} from "react"
import {useFabApp} from "../../../../hooks/useFabApp"
import {FModal} from "../../../ui/elements/FModal"
import {ICompany} from "../../../../interfaces/ICompany"
import {useCompaniesApi} from "../../../../api/base_apis"
import {useGlobalApi} from "../../../../contexts/GlobalApiProvider"

export const ModalSiteChooser: React.FC = () => {
    const {
        companiesApi: {
            data: companies, loading, error, forceRefresh, getItemById: getCompany},
        sitesApi: {data: sites,getItemById: getSite}
    } = useGlobalApi()
    const refSiteSelector = useRef<HTMLSelectElement>(null)
    const {isAuthenticated, siteChosen, companyChosen, setSiteChosen, setCompanyChosen, user} = useFabApp()
    const [selectedSite, setSelectedSite] = useState<ISite | null>(null)
    const [selectedCompany, setSelectedCompany,] = useState<ICompany | null>(null)
    let iOpt = 0
    const handleValidSite = () => {
        console.log("Change select")
        let siteId = Number.parseInt(refSiteSelector.current?.value ?? '0') ?? 0
        let site = getSite(siteId)
        console.log(site)
        if (!site) return
        let company = getCompany(site.companyId)
        if (!company) return
        setSelectedSite(site)
        setSelectedCompany(company)
        setCompanyChosen(company)
        setSiteChosen(site)
    }

    useEffect(() => {
        setSelectedSite(siteChosen)
        setSelectedCompany(companyChosen)
    }, [companyChosen, siteChosen])

    return (
        <FModal className="bg-white w-1/3 h-1/3 rounded-md m-auto text-center shadow-2xl"
                title={"Veuillez choisir un site"} onClose={() => setSiteChosen({
            id: 0,
            address: "",
            city: "",
            companyId: 0,
            code: "",
            zipCode: "",
            label: "AUCUN SITE !"
        })}>
            <>
                {loading && "Chargement des sites..."}
                {error && error.length > 0 &&
                    error.map((error: Error) => {
                        return <p
                            className="p-2 m-2 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error.message}</p>
                    })
                }
                {companies && companies.length > 0 && !loading &&
                    <select ref={refSiteSelector} value={companyChosen?.id} className="w-2/3 fab_button m-auto h-12 mt-10 mb-10 fab_input">
                        {
                            companies.map((company) => (
                                <optgroup label={company.label}>
                                    {
                                        company.Sites.length > 0 && company.Sites.map((site: ISite) => {
                                            let ret =
                                                <option key={site.id}
                                                        data-companyid={company.id} data-siteid={site.id} value={site.id}>
                                                    {site.label}
                                                </option>
                                            iOpt = iOpt + 1
                                            return ret
                                        })
                                    }
                                </optgroup>
                            ))
                        }
                    </select>
                }
                <Button text="Valider" loading={loading} onClick={handleValidSite} className={"w-1/6"}/>
            </>
        </FModal>
    )
}

