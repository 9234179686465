import {Button} from "../../ui/inputs/Button";
import React, {useEffect, useState} from "react";
import {
    GridColDef,
    GridRowModesModel, GridRowParams, ValueOptions
} from "@mui/x-data-grid";
import {FDataGrid} from "../../ui/elements/FDataGrid";
import { UserEditor } from "./editors/UserEditor";
import {User} from "../../../interfaces/User";
import {PasswordEditor} from "./editors/PasswordEditor";
import {useFabApp} from "../../../hooks/useFabApp";
import {LocalisationEditor} from "./editors/LocalisationEditor";
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {TablePageLayout} from "../layout/TablePageLayout"

export const UsersPage = () => {
    const {
        usersApi: {
            forceRefresh,
            data: users,
            loading,
            error,
            getItemById: getUserById,
            deleteItem: deleteUser,
            updateItem: updateUser,
            refreshItems: refreshUsers,
            resetApiError
        },
        sitesApi: {
            data: sites, getItemById: getSite
        },
        companiesApi: {data: companies, getItemById: getCompany},
        rolesApi: {data: fabRoles}
    } = useGlobalApi()
    const {user} = useFabApp()
    const [data, setData] = useState<User[]>([])
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [modalAddUserOpen, setModalAddUserOpen] = useState<boolean>(false)
    const [modalEditPassword, setModalEditPassword] = useState<boolean>(false)
    const [modalEditLocalisation, setModalEditLocalisation] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<User>()
    const [init, setInit] = useState<boolean>(false)
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [singleSelectSiteOpts, setSingleSelectSiteOpts] = useState<ValueOptions[]> ([])
    // const onCloseAddUserModal = (e: React.MouseEvent) => {
    //     setModalAddUserOpen(false)
    // }
    useEffect(() => {
        setData(users)
    }, [users])
    const resetError = () => {
        resetApiError()
    }
    const editUserPassword = (userId: number) => {
        if (!user) return
        if (!modalEditPassword)
        {
            const foundSelectedUser = getUserById(userId)
            if (foundSelectedUser != undefined) {
                if (foundSelectedUser.superUser != undefined && foundSelectedUser.superUser) {
                    if (!user.superUser) return
                }
                setSelectedUser(foundSelectedUser)
                setModalEditPassword(true);
            }
        }
    }
    const editUserLocalisation = (userId: number) => {
        if (!user) return
        if (!modalEditPassword)
        {
            const foundSelectedUser = getUserById(userId)
            if (foundSelectedUser != undefined) {
                if (foundSelectedUser.superUser != undefined && foundSelectedUser.superUser) {
                    if (!user.superUser) return
                }
                setSelectedUser(foundSelectedUser)
                setModalEditLocalisation(true);
            }
        }
    }
    const onCloseEditUserPassword = (e: React.MouseEvent) => {
        setModalEditPassword(false)
        setSelectedUser(undefined)
    }
    const onCloseEditLocalisation = (e: React.MouseEvent) => {
        setModalEditLocalisation(false)
        setSelectedUser(undefined)
        forceRefresh()
    }
    useEffect(() => {
        if (fabRoles.length <= 0 ) return;
        if (companies.length <= 0 ) return;
        if (sites.length <= 0) return;
        if (init) return;
        let singleSelectRoleOpts: ValueOptions[] = []
        fabRoles.map(role => {
            singleSelectRoleOpts.push({label: role.label, id: role.id})
        })
        setColumns([
            { field: 'fullName', headerName: 'Nom Complet', type: 'string', width: 150, editable:true},
            { field: 'email', headerName: 'E-Mail', type: 'string', width: 100, editable:true},
            { field: 'login', headerName: 'Identifiant WEB', type: 'string', width: 100, editable:true},
            { field: 'mobileLogin', headerName: 'Identifiant MOBILE', type: 'string', width: 100, editable:true},
            { field: 'companyId', headerName: 'Société', editable: false, width: 100, renderCell: (params: any) => {
                return getCompany(params.companyId)?.label ?? "N/A"
            }},
            { field: 'siteId', headerName: 'Site', editable: false, width: 100, renderCell: (params: any) => {
                return getSite(params.siteId)?.label ?? "N/A"
            }},
            {
                field: 'edit_company_site', headerName: '-', type: 'actions', width: 200, cellClassName: 'actions',
                renderCell: (params: any) => (
                        <Button
                            text={"Modifier la localisation"}
                            onClick={() => {
                                editUserLocalisation(params.id)
                            }}
                        />
                )
            },
            { field: 'roleId', headerName: 'Rôle', type:'singleSelect', valueOptions: singleSelectRoleOpts, editable: true, width: 150},
            {
                field: 'edit_password', headerName: '-', type: 'actions', width: 200, cellClassName: 'actions',
                renderCell: (params: any) => {
                    return [
                        <Button
                            text={"Modifier mot de passe"}
                            onClick={() => {
                                editUserPassword(params.id)
                            }}
                        />
                    ]
                }
            }
        ])
        setInit(true)
    }, [fabRoles, companies, sites, users]);
    useEffect(() => {
        if (!modalAddUserOpen) refreshUsers().then();
    }, [modalAddUserOpen]);
    const table = {
        rowModesModel: rowModesModel,
        setRowModesModel: setRowModesModel,
        deleteRow: deleteUser,
        updateRow: updateUser,
        rows: users,
        loading: loading,
        columns: columns
    }
    return (
        <>
            <TablePageLayout
                title={"UTILISATEUR"}
                Editor={UserEditor}
                forceRefreshData={forceRefresh}
                errors={error}
                resetError={resetApiError}
                loadingReference={loading}
                table={table}
            />

            {/*{*/}
            {/*    modalAddUserOpen && <UserEditor onClose={onCloseAddUserModal} setModalOpen={setModalAddUserOpen}/>*/}
            {/*}*/}
            {
                modalEditPassword && <PasswordEditor user={selectedUser} onClose={onCloseEditUserPassword} setModalOpen={setModalEditPassword}/>
            }
            {
                modalEditLocalisation && <LocalisationEditor user={selectedUser} onClose={onCloseEditLocalisation} setModalOpen={setModalEditLocalisation}/>
            }
        </>
    )
}