import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import React, {useEffect, useState} from "react"
import {FabOrder} from "../../../interfaces/FabOrder"
import {GridColDef, GridRowModesModel} from "@mui/x-data-grid"
import {useNavigate} from "react-router-dom"
import {Check, HourglassEmpty, QuestionMark, TapAndPlay} from "@mui/icons-material"
import {formatDate} from "../../../api/static_vars"
import {TablePageLayout} from "../../base/layout/TablePageLayout"
import {ProductionFabOrderEditor} from "../editors/ProductionFabOrderEditor"
import {FCheckBox} from "../../ui/inputs/FCheckBox"
import {FDataGrid, IFDataGrid} from "../../ui/elements/FDataGrid"
import {statusCodeToString} from "../../ui/utils/status_code_to_string"

export const AlertShippingPage = () => {
    const {
        sabcAlertShippingOrdersApi: {
            data: orders,
            loading,
            error,
            resetApiError,
            refreshItems: refreshOrders,
            forceRefresh: forceRefreshOrders,
            deleteItem: deleteFabOrder
        },
        customersApi: {data: customers, getItemById: getCustomer}
    } = useGlobalApi()
    const [dataLoading, setDataLoading] = React.useState<boolean>(loading)
    const [dataFiltered, setDataFiltered] = React.useState<FabOrder[]>(orders)
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const navigate = useNavigate()
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])
    useEffect(() => {
        forceRefreshOrders()
    }, [])
    useEffect(() => {
        setColumns(
            [
                {field: 'internalOrderId', headerName: 'Identifiant', editable: true, width: 200},
                {
                    field: 'statusCode',
                    headerName: 'Statut',
                    editable: true,
                    width: 300,
                    renderCell: (params: any) => {
                        switch (params.statusCode) {
                            case "NSTA" :
                                return <span className={"font-bold"}><HourglassEmpty/>En attente</span>
                            case "STAR" :
                                return <span className={"font-bold"}><TapAndPlay/> En cours</span>
                            case "DONE" :
                                return <span className={"font-bold"}><Check/>Clôturé</span>
                            default :
                                return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                        }

                    },
                    renderHeader: (params: any) => {
                        return statusCodeToString(params.statusCode)
                    }
                },
                {
                    field: 'executionDate',
                    headerName: "Date d'expédition",
                    type: 'dateTime',
                    width: 200
                },
                {
                    field: 'closingDate',
                    headerName: 'Date de clôture',
                    type: 'date',
                    width: 200
                },
                {
                    field: 'customerId',
                    headerName: 'Client',
                    editable: false,
                    width: 150,
                    renderCell: (params: any) => {
                        if (params.Lines.length == 0) return "N/A"
                        return getCustomer(params.Lines[0].customerId)?.label ?? "N/A"
                    },
                    renderHeader: (params: any) => {
                        if (params.Lines.length == 0) return ""
                        return getCustomer(params.Lines[0].customerId)?.label ?? ""
                    }
                }
            ]
        )
    }, [customers])
    const showDetails = (id: number) => {
        navigate(`/dashboard/shipping/${id}`)
    }
    useEffect(() => {
        if (customers.length <= 0) return
        //if (orders.length <= 0) return
        setDataFiltered(orders)
        setDataLoading(false)
    }, [orders, customers])
    const table: IFDataGrid = {
        rowModesModel: rowModesModel,
        setRowModesModel: setRowModesModel,
        rows: dataFiltered,
        loading: dataLoading,
        showMore: showDetails,
        deleteRow: deleteFabOrder,
        columns: columns
    }
    return (<>
        <TablePageLayout
            title={"ORDRES DE DISTRIBUTION EN ALERTE"}
            forceRefreshData={forceRefreshOrders}
            errors={error}
            resetError={resetApiError}
            loadingReference={loading}
            table={table}
            canExport
        />
    </>)
}