import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {useNavigate, useParams} from "react-router-dom"
import React, {useEffect, useState} from "react"
import {FabOrder, FabOrderLine, FabOrderLineMovement} from "../../../interfaces/FabOrder"
import {GridColDef, GridRowModesModel} from "@mui/x-data-grid"
import {Button} from "../../ui/inputs/Button"
import {ArrowPathIcon} from "@heroicons/react/24/solid"
import {
    ArrowLeft,
    Check,
    HourglassEmpty,
    Inventory,
    LocalShipping,
    QuestionMark,
    Rule,
    TapAndPlay
} from "@mui/icons-material"
import {FDataGrid} from "../../ui/elements/FDataGrid"
import {statusCodeToString} from "../../ui/utils/status_code_to_string"

const MovementsLineShippingViewer = () => {
    const {
        sabcShippingFabOrderApi: {data:orders, getItemById: getFabOrder, loading},
        productReferencesApi: {data: productReferences, getItemById: getProductReference},
        siteMovementsApi: {data: fabOrderLineMovements, getItemById: getFabOrderLineMovements, forceRefresh: forceMovements},
        customersApi: {getItemById: getCustomer},
    } = useGlobalApi()
    const {id, lineid} = useParams();
    const navigate = useNavigate();
    const [fabOrder, setFabOrder] = useState<FabOrder | undefined>();
    const [fabOrderLine, setFabOrderLine] = useState<FabOrderLine | undefined>();
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [orderMovements, setOrderMovements] = useState<FabOrderLineMovement[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    const showDetails = (id: number) => {
        navigate(`movements`)
    }
    useEffect(() => {
        forceMovements()
    }, [])
    useEffect(() => {
        setColumns([
            {
                field: 'Tag',
                headerName: 'EPC',
                width: 200,
                renderCell: (params: any) => params.Tag ? <p>{params.Tag.rfidValue}</p> : <p>''</p>,
                renderHeader: (params: any) => params.Tag ? params.Tag.rfidValue : ''
            },
            {
                field: 'tagStatusCode', headerName: 'Statut Contenant', width: 200, renderCell: (params: any) => {
                    if (params.tagStatusCode === null) return <span></span>
                    switch (params.tagStatusCode) {
                        case "PROD" :
                            return <span className={"font-bold"}><Inventory/>Produit</span>
                        case "EXPE" :
                            return <span className={"font-bold"}><LocalShipping/>Expedié</span>
                        case "DISP" :
                            return <span className={"font-bold"}><HourglassEmpty/>Disponible</span>
                        default :
                            return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                    }
                },
                renderHeader: (params: any)=> {
                    if (params.Tag === null) return ""
                    return statusCodeToString(params.tagStatusCode)
                }
            },
            {
                field: 'orderStatusCode', headerName: 'Statut Ordre', width: 200, renderCell: (params: any) => {
                    switch (params.orderStatusCode) {
                        case "NSTA" :
                            return <span className={"font-bold"}><HourglassEmpty/>En attente</span>
                        case "STAR" :
                            return <span className={"font-bold"}><TapAndPlay/> En cours</span>
                        case "DONE" :
                            return <span className={"font-bold"}><Check/>Clôturé</span>
                        case "ECAR" :
                            return <span className={"font-bold"}><Rule/>Ecarts</span>
                        case null:
                            return ""
                        default :
                            return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                    }
                },
                renderHeader: (params: any)=> {
                    return statusCodeToString(params.orderStatusCode)
                }
            },
            {
                field: 'productReferenceId',
                headerName: 'Produit',
                editable: true,
                width: 300,
                renderCell: (params: any) => {
                    const label = getProductReference(params.productReferenceId)?.label
                    return label ?? "N/A"
                }
            },
            {
                field: 'batch',
                headerName: 'Lot',
                type: 'string',
                editable: false,
                width: 150
            },
            {
                field: 'quantity',
                headerName: 'Quantité',
                type: 'string',
                editable: false,
                width: 150
            },
            {
                field: 'User',
                headerName: 'Utilisateur',
                width: 280,
                renderCell: (params: any) => params ? <p>{params.User.mobileLogin}</p>: <p>''</p>,
                renderHeader: (params:any )=> params ? params.User.mobileLogin : ''
            },
        ])
    }, [productReferences]);

    useEffect(() => {
        const idToCheck = Number(lineid);
        let movements = fabOrderLineMovements.filter(movement => {
            return movement.fabOrderLineId === idToCheck
        })
        console.log(movements)
        setOrderMovements(movements)

    }, [fabOrderLineMovements]);
    useEffect(() => {
        setFabOrder( prevState => {
            return getFabOrder(Number(id));
        })
    }, [id])
    useEffect(() => {
        const line = fabOrder?.Lines.find(line => line.id === Number(lineid))
        setFabOrderLine(line)
    }, [fabOrder])
    const goBack = () => {
        window.history.back()
    };
    return (<div className="w-full h-full bg-fab_app_container flex flex-col">
        {
            fabOrder !== undefined && fabOrderLine ? <>
                    <div className="panel_page_entete">
                        <span className="text-2xl m-auto">{fabOrder.internalOrderId}</span>
                    </div>
                    <Button onClick={forceMovements} loading={loading} className={"w-1/3"}
                            text={"Rafraîchir"}
                            leftIcon={<ArrowPathIcon/>}/>
                    <div>
                        <div className="w-1/2 m-auto bg-fab_congress-blue-200 mt-10 shadow-md rounded-md border-2">

                            <div className="flex w-full gap-5">
                                <span className={"text-2xl m-auto w-1/2 text-right"}>Statut : </span>
                                {fabOrder.statusCode == "NSTA" &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><HourglassEmpty/>En attente</span>}
                                {fabOrder.statusCode == "STAR" &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><TapAndPlay/> En cours</span>}
                                {fabOrder.statusCode == "DONE" &&
                                    <span
                                        className={"text-2xl m-auto font-bold w-1/2 text-left"}><Check/>Terminé</span>}
                                {fabOrder.statusCode == "ECAR" &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><Rule/>Ecarts</span>}
                                {fabOrder.statusCode == "" || fabOrder.statusCode == null &&
                                    <span className={"text-2xl m-auto font-bold w-1/2 text-left"}><QuestionMark/>Inconnu</span>}
                            </div>

                            <div className="flex w-full gap-5">
                                <span className={"text-2xl m-auto w-1/2 text-right"}>Client / Destinataire :</span>
                                <span
                                    className={"text-2xl m-auto font-bold w-1/2 text-left"}>{getCustomer(fabOrder.Lines[0].customerId)?.label}</span>
                            </div>

                            <div className="flex w-full gap-5">
                                <span className={"text-2xl m-auto w-1/2 text-right"}>Produit :</span>
                                <span
                                    className={"text-2xl m-auto font-bold w-1/2 text-left"}>{getProductReference(fabOrderLine.productReferenceId)?.label}</span>
                            </div>

                            <div className="flex w-full gap-5">
                                <span className={"text-2xl m-auto w-1/2 text-right"}>Quantité :</span>
                                <span
                                    className={"text-2xl m-auto font-bold w-1/2 text-left"}>{fabOrderLine.quantityDone} / { fabOrderLine.quantity}</span>
                            </div>

                        </div>

                    </div>

                    <div className="w-full m-auto h-1/2 flex flex-col">
                        <FDataGrid
                            rowModesModel={rowModesModel}
                            setRowModesModel={setRowModesModel}
                            rows={orderMovements}
                            loading={false}
                            columns={columns}
                            disableEdit
                        />

                    </div>
                </>
                :
                <>
                    <span>Introuvable</span>
                </>
        }
        <Button text={"Retour"} onClick={goBack} leftIcon={<ArrowLeft/>}/>
    </div>);
}
export default MovementsLineShippingViewer;