import React, {useEffect, useState} from "react";
import {GridRowModesModel} from "@mui/x-data-grid";
import {FDataGrid} from "../../ui/elements/FDataGrid";
import {RoleEditor} from "./editors/RoleEditor";
import {TablePageLayout} from "../layout/TablePageLayout"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"

export const RolesPage = () => {
    const {
        rolesApi: {forceRefresh, data: roles, loading , error: error, resetApiError, updateItem: updateRole, deleteItem: deleteRole, refreshItems: refreshRoles}} = useGlobalApi()
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [modalAddRoleOpen, setModalAddRoleOpen] = useState<boolean>(false)
    const columns: any[] = [
        { field: 'label', headerName: 'Nom', type: 'string', width: 370, editable:true},
        { field: 'adminAccess', headerName: 'Acces Admin', type: 'boolean', width: 100, editable:true},
        { field: 'webAccess', headerName: 'Acces Web', type: 'boolean', width: 100, editable:true},
        { field: 'mobileAccess', headerName: 'Acces Mobile', type: 'boolean', width: 100, editable:true},
        // { field: 'teamManager', headerName: 'Gestionnaire', type: 'boolean', width: 100, editable:true},
    ]
    useEffect(() => {
        if (!modalAddRoleOpen) refreshRoles().then();
    }, [modalAddRoleOpen]);
    return (
        <>
            <TablePageLayout
                title={"RÔLE"}
                Editor={RoleEditor}
                forceRefreshData={forceRefresh}
                errors={error}
                resetError={resetApiError}
                loadingReference={loading}
            >
                <FDataGrid
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    updateRow={updateRole}
                    deleteRow={deleteRole}
                    rows={roles}
                    loading={loading}
                    columns={columns}
                />
            </TablePageLayout>
        </>
    )
}