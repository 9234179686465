import './App.css';
import FabAppProvider from './contexts/FabAppProvider';
import {Routes, Route, HashRouter} from 'react-router-dom';
import {AuthenticatedRoute} from './components/base/layout/AuthenticatedRoute';
import {Dashboard, DashBoardPage} from './components/base/pages/Dashboard';
import {Login} from './components/base/pages/Login';
import {NotFound} from './components/base/pages/404';
import {CompaniesPage} from "./components/base/pages/CompaniesPage";
import {SitesPage} from "./components/base/pages/SitesPage";
import {RolesPage} from "./components/base/pages/RolesPage";
import {UsersPage} from "./components/base/pages/UsersPage";
import {ApplicationDevicesPage} from "./components/base/pages/ApplicationDevicesPage";
import {ContainerTypesPage} from "./components/base/pages/ContainerTypesPage";
import {ProductCategoriesPage} from "./components/base/pages/ProductCategoriesPage";
import {ProductReferencesPage} from "./components/base/pages/ProductReferencesPage";
import {CustomerPage} from "./components/base/pages/CustomerPage";
import {ContainerList} from "./components/sabc/pages/ContainersList";
import { ProductionPage } from "./components/sabc/pages/ProductionPage";
import { ShippingPage } from "./components/sabc/pages/ShippingPage";
import { ProductionFabOrderViewer } from "./components/sabc/viewers/ProductionFabOrderViewer";
import { ShippingFabOrderViewer } from "./components/sabc/viewers/ShippingFabOrderViewer";
import {GlobalApiProvider} from "./contexts/GlobalApiProvider"
import {AlertProductionPage} from "./components/sabc/pages/AlertProductionPage"
import {AlertShippingPage} from "./components/sabc/pages/AlertShippingPage"
import {TraceabilityPage} from "./components/sabc/pages/TraceabilityPage"
import {SiteMovementsPageSabc} from "./components/sabc/pages/SiteMovementsPageSabc"
import MovementsLineShippingViewer from "./components/sabc/viewers/MovementsLineShippingViewer"
function App() {
    // @ts-ignore
    const basename = window.config.REACT_APP_BASENAME ?? '/';
    return (
        <FabAppProvider>

            <div className="flex w-full h-screen">
                <HashRouter basename={basename}>
                    <div className="w-full">
                        <Routes>
                            <Route path="/" element={<Login/>}/>
                            <Route path="/*" element={<NotFound/>}/>
                            <Route path="/login" element={<Login/>}/>
                                <Route path="/dashboard"
                                       element={
                                           <AuthenticatedRoute element={<Dashboard/>}/>
                                       }>
                                    <Route path="" element={<AuthenticatedRoute element={<DashBoardPage/>}/>}/>
                                    <Route path="*"                                 element={<NotFound/>}/>
                                    <Route path="production"
                                           element={<AuthenticatedRoute element={<ProductionPage/>}/>}/>
                                    <Route path="shipping"
                                           element={<AuthenticatedRoute element={<ShippingPage/>}/>}/>
                                    <Route path="traceability"
                                           element={<AuthenticatedRoute element={<TraceabilityPage/>}/>}/>
                                    <Route path="production/:id"
                                           element={<AuthenticatedRoute element={<ProductionFabOrderViewer/>}/>}/>
                                    <Route path="shipping/:id"
                                           element={<AuthenticatedRoute element={<ShippingFabOrderViewer />}/>}/>
                                    <Route path="shipping/:id/movements/:lineid"
                                           element={<AuthenticatedRoute element={<MovementsLineShippingViewer />}/>}/>
                                    <Route path="companies"
                                           element={<AuthenticatedRoute admin superUser element={<CompaniesPage/>}/>}/>
                                    <Route path="sites"
                                           element={<AuthenticatedRoute admin superUser element={<SitesPage/>}/>}/>
                                    <Route path="customers"
                                           element={<AuthenticatedRoute admin superUser element={<CustomerPage/>}/>}/>
                                    <Route path="movements"
                                           element={<AuthenticatedRoute element={<SiteMovementsPageSabc/>}/>}/>
                                    <Route path="roles"
                                           element={<AuthenticatedRoute admin superUser element={<RolesPage/>}/>}/>
                                    <Route path="users"
                                           element={<AuthenticatedRoute admin superUser element={<UsersPage/>}/>}/>
                                    <Route path="product_categories"
                                           element={<AuthenticatedRoute admin superUser element={<ProductCategoriesPage />}/>}/>
                                    <Route path="product_references"
                                           element={<AuthenticatedRoute admin superUser  element={<ProductReferencesPage />}/>}/>
                                    <Route path="devices"
                                           element={<AuthenticatedRoute superUser element={<ApplicationDevicesPage />}/>}/>
                                    <Route path="container_types"
                                           element={<AuthenticatedRoute superUser element={<ContainerTypesPage />}/>}/>
                                    <Route path="sabc/containers"
                                           element={<AuthenticatedRoute element={<ContainerList />}/>}/>
                                    <Route path="alerts_production"
                                           element={<AuthenticatedRoute element={<AlertProductionPage />}/>}/>
                                    <Route path="alerts_shipping"
                                           element={<AuthenticatedRoute element={<AlertShippingPage />}/>}/>
                                </Route>
                        </Routes>
                    </div>
                </HashRouter>
            </div>
        </FabAppProvider>
    );
}

export default App;
