import React, {useEffect, useState} from "react"
import {GridColDef, GridRowModesModel} from '@mui/x-data-grid'
import {FDataGrid, IFDataGrid} from '../../ui/elements/FDataGrid'
import {CustomerEditor} from "./editors/CustomerEditor"
import {TablePageLayout} from "../layout/TablePageLayout"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"

export const CustomerPage = () => {
    const {
        customersApi: {
            forceRefresh,
            data: customers,
            loading,
            error,
            resetApiError,
            updateItem: updateCustomer,
            deleteItem: deleteCustomer,
            refreshItems: refreshCustomers
        }
    } = useGlobalApi()
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])
    const [modalAddCustomerOpen, setModalAddCustomerOpen] = useState<boolean>(false)
    const openAddCustomerModal = () => {
        setModalAddCustomerOpen(true)
    }
    useEffect(() => {
        setColumns([
            {field: 'label', headerName: 'Nom', editable: true, width: 100},
            {field: 'code', headerName: 'Code client', editable: true, width: 100},
            {field: 'address', headerName: 'Addresse', editable: true, width: 200},
            {field: 'zipCode', headerName: 'Code Postal', editable: true, width: 100},
            {field: 'city', headerName: 'Ville', editable: true, width: 170}
        ])
    }, [customers])

    useEffect(() => {
        if (!modalAddCustomerOpen) refreshCustomers().then(r => r)
    }, [modalAddCustomerOpen])


    const table: IFDataGrid = {
        rowModesModel: rowModesModel,
        setRowModesModel: setRowModesModel,
        updateRow: updateCustomer,
        deleteRow: deleteCustomer,
        rows: customers,
        loading: loading,
        columns: columns
    }
    return (
        <>
            <TablePageLayout
                title={"CLIENT"}
                Editor={CustomerEditor}
                forceRefreshData={forceRefresh}
                errors={error}
                resetError={resetApiError}
                loadingReference={loading}
                table={table}
            />
        </>
    )
}