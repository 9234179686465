import React, {FC, useRef, useState} from "react"
import {IFModal} from "../../../ui/interfaces/elements/IFModal"
import {FModal} from "../../../ui/elements/FModal"
import {ProductReference} from '../../../../interfaces/Product'
import {FabFile} from '../../../../interfaces/Image'
import {Field, FormGenerator} from "../../../ui/elements/FabForm"
import {useGlobalApi} from "../../../../contexts/GlobalApiProvider"

export const ProductReferenceEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const {
        productReferencesApi: {addItem: addProductReference, loading, error, resetApiError},
        productCategoriesApi: {data: productCategories},
        containerTypesApi: {data: containerTypes },
        productImageApi: {addItem: uploadImage},

    } = useGlobalApi()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [currentImageFile, setCurrentImageFile] = useState<File | null>(null);
    const fields: Field[] = [
        {label: 'Nom du produit', name: 'label', type: 'text', required: true},
        {label: 'Référence du produit', name: 'code', type: 'text', required: true},
        {label: 'Catégorie', name: 'productCategoryId', type: 'autocomplete', required: true, data: productCategories},
        {label: 'Conditionnement', name: 'containerTypeId', type: 'autocomplete', required: true, data: containerTypes},
        {label: 'Quantité dans le conditionnement', name: 'containerUnity', type: 'number', required: true},
    ]
    function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        if (e.target.files != undefined && e.target.files.length > 0) {
            let selectedFile = e.target.files[0]
            if (selectedFile && selectedFile.type.includes('image')) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    setImagePreview(reader.result as string)
                    setCurrentImageFile(selectedFile)
                }
                reader.readAsDataURL(selectedFile)
                return
            }
        }
        setImagePreview(null)
    }
    const onValidAddProductReference = async (data: any) => {

        const productReferenceToAdd: ProductReference = {
            id: 0,
            label: data.label,
            code: data.code,
            useBatch: true,
            productCategoryId: data.productCategoryId,
            containerTypeId: data.containerTypeId,
            containerUnity: data.containerUnity,
        }
        if (currentImageFile) {
            // On upload l'image avant tout
            const fabFile: FabFile = currentImageFile as FabFile
            productReferenceToAdd.imageId = await uploadImage(fabFile) as number
        }
        addProductReference(productReferenceToAdd).then((r) => {
                if (r && setModalOpen) setModalOpen(false)
            }
        )
    }
    return (
        <FModal title={"Ajout d'une référence produit"}
                className="fab_editor_modal"
                canClose onClose={onClose}>
            <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
                <div className="m-auto h-36 w-36 border flex items-center justify-center">
                    { imagePreview ? <img src={imagePreview} alt="preview" className="h-full w-full object-scale-down"/> : <span>Aucune image</span>}
                </div>
                <label className="m-auto fab_button button_full w-1/3 flex justify-center items-center ">
                    <span className="">Choisir une image</span>
                    <input
                        accept="image/*"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        className="hidden h-0"
                        type="file"
                    />
                </label>
                <FormGenerator loading={loading} fields={fields} onSubmit={onValidAddProductReference}
                               apiError={error} resetApiError={resetApiError}/>
            </div>
        </FModal>)
}
